import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';

import NoResultModal from '../../components/Modal/NoResultModal';
import Backdrop from '../../components/Modal/Backdrop';

import logo from '../../asset/GLOT logo.png';

import { UsePostApi } from '../../services/http';

export default function NewPw() {
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const userId = searchParams.get('accountId');
  const userCode = searchParams.get('code');

  const [isModalOpen, setIsModalOpen] = useState(false); //팝업창 제어
  const [pw, setPw] = useState('');
  const [pwCheck, setPwCheck] = useState('');
  const [isSame, setIsSame] = useState(true); //비밀번호와 비밀번호 확인 일치 여부
  const [pwCheckInputCompleted, setPwCheckInputCompleted] = useState(false); //비밀번호 확인 입력 완료 여부

  useEffect(() => {
    if (pwCheck !== '') {
      setPwCheckInputCompleted(true);
      if (pwCheck === pw) {
        setIsSame(true);
      } else {
        setIsSame(false);
      }
    } else {
      setPwCheckInputCompleted(false);
    }
  }, [pwCheck, pw]);

  const onClickChangePw = async () => {
    try {
      const response = await UsePostApi('/reset/password', { accountId: userId, code: userCode, password: pw });
      if (response.status === 204) {
        alert('비밀번호가 변경되었습니다.');
        navigate('/login');
      } else {
        alert('비밀번호 변경에 실패하였습니다.');
      }
    } catch (error) {
      setIsModalOpen(true);
    }
  };

  return (
    <Container>
      <InputArea>
        <LogoArea>
          <Logo src={logo} />
        </LogoArea>
        <InnerContainer>
          <Content>
            <Title>새로운 비밀번호 설정하기</Title>
            <SubTitle>비밀번호를 재설정 해주세요.</SubTitle>
          </Content>
          <InputContainer
            style={{
              marginBottom: isSame ? '28px' : '3px'
            }}
          >
            <Naming>비밀번호</Naming>
            <Input
              placeholder="영문,숫자 조합 8자리 이상"
              type="password"
              value={pw}
              onChange={e => setPw(e.target.value)}
            />
            <Naming>비밀번호 확인</Naming>
            <Input
              placeholder="비밀번호 재입력"
              type="password"
              value={pwCheck}
              onChange={e => setPwCheck(e.target.value)}
              style={{
                marginBottom: '0px'
              }}
            />
            {!isSame && pwCheckInputCompleted ? <Warning>비밀번호가 일치하지 않습니다.</Warning> : null}
          </InputContainer>
          <ButtonArea>
            <Button
              onClick={onClickChangePw}
              disabled={!isSame || !pwCheckInputCompleted} // 비밀번호와 비밀번호 확인이 일치하지 않으면 버튼 비활성화
              style={{
                backgroundColor: isSame && pwCheckInputCompleted ? '#3290ff' : '#eaebed',
                color: isSame && pwCheckInputCompleted ? '#FFFFFF' : '#97989a'
              }}
            >
              변경
            </Button>
            <Button style={{ backgroundColor: '#eaebed', color: '#97989a' }} onClick={() => navigate('/login')}>
              취소
            </Button>
          </ButtonArea>
        </InnerContainer>
      </InputArea>
      {isModalOpen && <NoResultModal setIsModal={setIsModalOpen} />}
      {isModalOpen && <Backdrop />}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
  }
`;

const InputArea = styled.div`
  width: 700px;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const LogoArea = styled.div`
  width: 100%;
  height: 192px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
    height: 120px;
  }
`;

const Logo = styled.img`
  width: 127px;
  height: 56px;
`;

const InnerContainer = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  item-align: center;
  text-align: center;
`;

const Title = styled.div`
  width: 100%;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.5px;
  text-align: center;
  white-space: pre-line;
  @media (max-width: 768px) {
    font-size: 24px;
    font-weight: 600;
  }
`;

const SubTitle = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.5px;
  text-align: center;
  white-space: pre-line;
  margin-top: 10px;
  color: #b7b8ba;
  @media (max-width: 768px) {
    font-size: 12px;
    font-weight: 500;
  }
`;

const InputContainer = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 56px;
  margin-bottom: 28px;
  @media (max-width: 768px) {
    width: 327px;
  }
`;

const Naming = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #6f7071;
  padding-left: 3px;
  margin-bottom: 6px;
`;

const Input = styled.input`
  width: 385px;
  height: 56px;
  border: 1px solid #eaebec;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 400;
  padding-left: 15px;
  color: #b7b8ba;
  margin-bottom: 28px;
  @media (max-width: 768px) {
    width: calc(100% - 23px);
  }
`;

const Warning = styled.div`
  width: 100%;
  height: 20px;
  font-size: 13px;
  font-weight: 400;
  color: #ff0000;
  padding-left: 3px;
  margin-top: 5px;
`;

const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4px;
  @media (max-width: 768px) {
    width: 327px;
  }
`;

const Button = styled.div`
  width: 195px;
  height: 56px;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 155px;
  }
`;
