import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useRecoilState } from 'recoil';
import { UserType, LoginState } from '../../data/Atom';
import styled from 'styled-components';

import Header from '../../components/Header';
import ChangePw from '../../components/Modal/ChangePwModal';

import { UseGetApi, UsePostApi } from '../../services/http';
import MyInfoEditApi from '../../services/MyInfoEdit';
import { isAxiosError } from 'axios';

import { validatePhoneNumber } from '../../util/validate';

export default function Mypage() {
  const navigate = useNavigate();
  const location = useLocation();

  const [userType, setUserType] = useRecoilState(UserType);
  const [loginState, setLoginState] = useRecoilState(LoginState);
  const [myInfo, setMyInfo] = useState({});
  const [nameEditing, setNameEditing] = useState(false);
  const [changeableMobile, setChangeableMobile] = useState('');
  const [mobileEditing, setMobileEditing] = useState(false);
  const [emailEditing, setEmailEditing] = useState(false);
  const [mobileAuthCode, setMobileAuthCode] = useState('');
  const [auth, setAuth] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [modal, setModal] = useState(false); // 비밀번호 변경 모달

  const isPersonalUser = localStorage.getItem('role') === 'ROLE_PERSONAL' ? true : false;

  const onClickGroupInfo = () => {
    if (userType !== 'SUBSCRIBED' || isPersonalUser || myInfo.userType === '기관 멤버') {
      alert('유료 요금제를 가입한 기관 계정만 이용할 수 있는 기능입니다.');
      return;
    }

    navigate('/mypage/groupinfo');
  };

  const onClickPricePlan = () => {
    if (userType !== 'FREE') {
      navigate('/mypage/paid_info');
    } else {
      navigate('/mypage/free_info');
    }
  };

  const fetchData = async () => {
    try {
      const res = await UseGetApi('/user/info', { auth: localStorage.getItem('token') });
      if (res.status >= 200 && res.status < 300) {
        setMyInfo(res.data);
        setChangeableMobile(res.data.mobile);
      } else {
        alert('로그인이 만료되었습니다. 다시 로그인해주세요.');
        navigate('/login');
      }
    } catch (err) {
      alert('로그인이 만료되었습니다. 다시 로그인해주세요.');
      navigate('/login');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onClickEdit = async () => {
    try {
      // API 호출
      await MyInfoEditApi(nameEditing ? myInfo.name : null, null, emailEditing ? myInfo.email : null, null, null);
    } catch (error) {
      console.log(error);
    }
  };

  const onClickAuth = async () => {
    if (changeableMobile.length !== 11) {
      return;
    }

    if (!window.confirm(`${changeableMobile}로 인증번호를 전송합니다.`)) {
      return;
    }

    try {
      await UsePostApi('/cert/sms/code', { mobile: changeableMobile });
      setAuth(true);
      alert('인증번호가 전송되었습니다.');
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response.status === 400 && error.response.data.name === 'INVALID_PHONE_NUMBER') {
          alert('정확한 휴대 전화번호를 입력해주세요.');
          return;
        }
      }

      alert('인증번호 전송에 실패했습니다. 다시 시도해주세요.');
    }
  };

  const onClickAuthCode = async () => {
    try {
      const response = await UsePostApi('/cert/sms/verify', { code: mobileAuthCode });
      if (response.data.success) {
        alert('인증되었습니다.');
        setMobileEditing(false);
        setAuth(false);
        await MyInfoEditApi(null, changeableMobile, null, null, null);
        setMyInfo({ ...myInfo, mobile: changeableMobile });
      }
    } catch (error) {
      alert('인증번호가 일치하지 않습니다.');
    }
  };

  const handleLogout = async () => {
    try {
      const response = await UsePostApi(
        '/auth/logout',
        {},
        { auth: localStorage.getItem('token'), refresh: cookies.token }
      );

      const handleLogoutSuccess = () => {
        alert('로그아웃 되었습니다.');
        localStorage.removeItem('token');
        localStorage.removeItem('userID');
        localStorage.removeItem('role');
        removeCookie('token');
        setLoginState(false);
        setUserType('FREE');
        navigate('/');
      };

      if (response.status === 204) {
        handleLogoutSuccess();
      }
    } catch (error) {
      if (error.response.status >= 400 && error.response.status < 500) {
        alert('세션이 만료되었습니다. 다시 로그인해주세요.');
        removeCookie('token');
        localStorage.removeItem('token');
        localStorage.removeItem('userID');
        localStorage.removeItem('role');
        setLoginState(false);
        setUserType('FREE');
        navigate('/login');
      }
    }
  };

  const onClickLogout = () => {
    window.confirm('로그아웃 하시겠습니까?') && handleLogout();
  };

  return (
    <Container>
      <Header />
      <Content>
        <SideContent>
          <SideTitle>마이페이지</SideTitle>
          <Menu isActive={location.pathname === '/mypage'} onClick={() => navigate('/mypage')}>
            내 정보
          </Menu>

          <Menu isActive={location.pathname === '/mypage/groupinfo'} onClick={onClickGroupInfo}>
            구매 계정 정보
          </Menu>

          <Menu
            isActive={location.pathname === '/mypage/paid_info' || location.pathname === '/mypage/free_info'}
            onClick={onClickPricePlan}
          >
            결제 정보
          </Menu>
        </SideContent>
        <InnerContent>
          <InnerContainer>
            <Title>내 정보</Title>
            <InputArea>
              <InputName>이용 중인 플랜</InputName>
              <DataBox
                value={myInfo.subscription}
                readOnly
                style={{
                  backgroundColor: '#F2F3F5',
                  outline: 'none'
                }}
              />
              <ButtonArea />
            </InputArea>
            <InputArea>
              <InputName>고객 유형</InputName>
              <DataBox
                value={myInfo.userType}
                readOnly
                style={{
                  backgroundColor: '#F2F3F5',
                  outline: 'none'
                }}
              />
              <ButtonArea />
            </InputArea>
            <InputArea>
              <InputName>이름</InputName>
              <DataBox
                value={myInfo.name}
                onChange={e => setMyInfo({ ...myInfo, name: e.target.value })}
                disabled={!nameEditing}
              />
              {nameEditing ? (
                <ButtonArea>
                  <EditButton
                    style={{ color: 'rgba(183, 184, 186, 1)' }}
                    onClick={() => {
                      setNameEditing(false);
                    }}
                  >
                    취소
                  </EditButton>
                  <EditButton
                    onClick={() => {
                      setNameEditing(false);
                      onClickEdit();
                    }}
                  >
                    저장
                  </EditButton>
                </ButtonArea>
              ) : (
                <ButtonArea
                  style={{
                    justifyContent: 'flex-end'
                  }}
                >
                  <EditButton
                    onClick={() => {
                      setNameEditing(true);
                    }}
                  >
                    수정
                  </EditButton>
                </ButtonArea>
              )}
            </InputArea>
            {/* 기관에서 할당한 멤버 계정의 경우 전화번호, 휴대전화번호, 이메일 삭제 */}
            {myInfo.userType !== '기관 멤버' && (
              <>
                <InputArea>
                  <InputName>전화번호</InputName>
                  <DataBox
                    value={myInfo.phone}
                    readOnly
                    style={{
                      backgroundColor: '#F2F3F5',
                      outline: 'none'
                    }}
                  />
                  <ButtonArea />
                </InputArea>
                <InputArea>
                  <InputName>휴대전화번호</InputName>
                  <DataBox
                    value={changeableMobile}
                    onChange={e => {
                      if (!validatePhoneNumber(e.target.value)) {
                        return;
                      }

                      setChangeableMobile(e.target.value);
                    }}
                    disabled={!mobileEditing}
                  />
                  {mobileEditing ? (
                    <ButtonArea
                      style={{
                        width: '150px',
                        left: '250px',
                        display: auth ? 'none' : 'flex'
                      }}
                    >
                      <EditButton
                        onClick={onClickAuth}
                        style={{ ...(changeableMobile.length !== 11 && { opacity: 0.5, cursor: 'not-allowed' }) }}
                      >
                        인증번호 발송
                      </EditButton>
                      <EditButton
                        onClick={() => {
                          setChangeableMobile(myInfo.mobile);
                          setMobileEditing(false);
                        }}
                        style={{ color: 'rgba(183, 184, 186, 1)' }}
                      >
                        취소
                      </EditButton>
                    </ButtonArea>
                  ) : (
                    <ButtonArea
                      style={{
                        justifyContent: 'flex-end'
                      }}
                    >
                      <EditButton
                        onClick={() => {
                          setChangeableMobile(myInfo.mobile);
                          setMobileEditing(true);
                        }}
                      >
                        수정
                      </EditButton>
                    </ButtonArea>
                  )}
                  <DataBox
                    placeholder="인증번호를 입력하세요."
                    value={mobileAuthCode}
                    onChange={e => setMobileAuthCode(e.target.value)}
                    style={{
                      display: auth ? 'block' : 'none'
                    }}
                  />
                  <ButtonArea
                    style={{
                      display: auth ? 'flex' : 'none'
                    }}
                  >
                    <EditButton onClick={onClickAuthCode}>인증</EditButton>
                    <EditButton
                      onClick={() => {
                        setAuth(false);
                      }}
                      style={{ color: 'rgba(183, 184, 186, 1)' }}
                    >
                      취소
                    </EditButton>
                  </ButtonArea>
                </InputArea>
                <InputArea>
                  <InputName>이메일</InputName>
                  <DataBox
                    value={myInfo.email}
                    onChange={e => setMyInfo({ ...myInfo, email: e.target.value })}
                    disabled={!emailEditing}
                  />
                  {emailEditing ? (
                    <ButtonArea>
                      <EditButton
                        style={{ color: 'rgba(183, 184, 186, 1)' }}
                        onClick={() => {
                          setEmailEditing(false);
                        }}
                      >
                        취소
                      </EditButton>
                      <EditButton
                        onClick={() => {
                          setEmailEditing(false);
                          onClickEdit();
                        }}
                      >
                        저장
                      </EditButton>
                    </ButtonArea>
                  ) : (
                    <ButtonArea
                      style={{
                        justifyContent: 'flex-end'
                      }}
                    >
                      <EditButton
                        onClick={() => {
                          setEmailEditing(true);
                        }}
                      >
                        수정
                      </EditButton>
                    </ButtonArea>
                  )}
                </InputArea>
              </>
            )}
            <InputArea>
              <InputName>아이디</InputName>
              <DataBox
                value={myInfo.accountId}
                readOnly
                style={{
                  backgroundColor: '#F2F3F5',
                  outline: 'none'
                }}
              />
              <ButtonArea />
            </InputArea>
            <InputArea>
              <InputName>비밀번호</InputName>
              <DataBox
                placeholder="••••••••"
                value={myInfo.pw}
                onChange={e => setMyInfo({ ...myInfo, password: e.target.value })}
                type="password"
              />
              <ButtonArea
                style={{
                  justifyContent: 'flex-end'
                }}
              >
                <EditButton
                  onClick={() => {
                    setModal(true);
                    window.scrollTo(0, 0);
                  }}
                >
                  수정
                </EditButton>
              </ButtonArea>
            </InputArea>
            <BottomArea>
              <Button onClick={onClickLogout}>로그아웃</Button>
            </BottomArea>
          </InnerContainer>
        </InnerContent>
      </Content>
      {modal && <ChangePw setModal={setModal} id={myInfo.accountId} />}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  width: 100%;
  height: calc(100% - 80px);
  display: flex;
  overflow: auto;
`;

const SideContent = styled.div`
  width: 176px;
  padding: 28px;
  background-color: #fcfcfc;
  border-right: 1px solid #f2f3f5;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.04);
`;

const SideTitle = styled.div`
  width: 100%;
  font-size: 22px;
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 14px;
`;

const Menu = styled.div`
  width: 100%;
  height: 40px;
  font-size: 15px;
  font-weight: ${({ isActive }) => (isActive ? 700 : 500)};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  &:hover {
    font-weight: 700;
  }
`;

const InnerContent = styled.div`
  width: calc(100% - 176px);
  height: 100%;
  padding: 48px;
  display: flex;
  justify-content: center;
  overflow: auto;
  flex: 1;
`;

const InnerContainer = styled.div`
  width: 400px;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 98px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`;

const InputArea = styled.div`
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const InputName = styled.div`
  width: 200px;
  font-size: 14px;
  font-weight: 500;
  color: #6f7071;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const DataBox = styled.input`
  width: 384px;
  height: 48px;
  border: 1px solid #eaebed;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  color: #6f7071;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
  padding-left: 16px;
  &:disabled {
    background-color: #fff;
  }
`;

const ButtonArea = styled.div`
  position: relative;
  bottom: 31px;
  left: 300px;
  width: 100px;
  height: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EditButton = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: rgba(50, 144, 255, 1);
  cursor: pointer;
  margin-right: 16px;
`;

const BottomArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 20px;
`;

const Button = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: rgba(50, 144, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
