const NAME = 'TODAY_NOT_USING_WRITING_GUIDE';

export const setTodayNotUsingWritingGuide = () => {
  const date = new Date();
  const tomorrowDate = date.setTime(date.getTime() + 24 * 60 * 60 * 1000);

  localStorage.setItem(NAME, tomorrowDate);
};

export const getTodayNotUsingWritingGuide = () => {
  const currentDate = localStorage.getItem(NAME);
  if (!currentDate) {
    return false;
  }

  const now = new Date();
  const nowDate = now.setTime(now.getTime());

  return parseInt(localStorage.getItem(NAME)) > nowDate;
};
