import React, {useState} from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import logo from "../../asset/mainLogoBlack.png";

import { UsePostApi } from "../../services/http";

export default function Login() {
    const navigate = useNavigate();

    const [id, setId] = useState("");
    const [password, setPassword] = useState("");

    const onChangeId = (e) => {
        setId(e.target.value);
    }

    const onChangePassword = (e) => {
        setPassword(e.target.value);
    }

    const onClickLogin = async () => {
        try {
            const response = await UsePostApi('/admin/login', { accountId: id, password: password });
            localStorage.setItem("admin_Token", response.data.token.accessToken);
            navigate("/admin/user");
        } catch (error) {
            alert(error.message)
        }
    }
    return (
        <Container>
            <InnerContainer>
                <Logo>
                    <img src={logo} alt="logo" />
                </Logo>
                <Line>
                    <Name>아이디</Name>
                    <Input type="text" placeholder="아이디를 입력해주세요" onChange={onChangeId} />
                </Line>
                <Line>
                    <Name>비밀번호</Name>
                    <Input type="password" placeholder="비밀번호를 입력해주세요" onChange={onChangePassword} />
                </Line>
                <Line>
                    <Button onClick={onClickLogin}>로그인</Button>
                </Line>
            </InnerContainer>
        </Container>
    );
}


const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
`;

const InnerContainer = styled.div`
    width: 400px;
    height: 400px;
    border-radius: 10px;
    padding: 30px;
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
`;

const Logo = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 100px;
`;

const Line = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
`;

const Name = styled.div`
    font-size: 16px;
    margin-bottom: 10px;
`;

const Input = styled.input`
    width: 100%;
    height: 56px;
    border-radius: 10px;
    border: 1px solid #E5E5E5;
    padding: 0 10px;
    box-sizing: border-box;
`;

const Button = styled.button`
    width: 100%;
    height: 56px;
    border-radius: 10px;
    border: none;
    background-color: #2390ff;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
`;
