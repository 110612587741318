import { toast } from 'react-toastify'

export const customNotify = (message, type) => {
  const toastMethods = {
    success: toast.success,
    error: toast.error,
    warning: toast.warning,
    info: toast.info
  }
  const showMessage = toastMethods[type] || toast.info
  showMessage(message)
}
