import React, { useRef } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { UserType } from '../../data/Atom';

import { UsePostApi } from '../../services/http';
import { isAxiosError } from 'axios';

function OrderCheck(props) {
  const [userType, setUserType] = useRecoilState(UserType);

  const isRequested = useRef(false);

  const onClickOrder = async () => {
    if (isRequested.current) {
      return;
    }

    try {
      // start loading
      isRequested.current = true;

      const planId =
        props.planName === 'GLOT 베이직'
          ? props.pricingType === 'perYear'
            ? 4
            : 2
          : props.pricingType === 'perYear'
          ? 5
          : 3;
      const quantity = props.userNumber;
      const month = props.expiryMonth < 10 ? `0${props.expiryMonth}` : props.expiryMonth;
      const expiry = `${props.expiryYear}-${month}`;

      const finalData = {
        ...props.payment,
        expiry
      };

      await UsePostApi(
        '/orders',
        { planId: planId, quantity: quantity, payment: finalData },
        { auth: localStorage.getItem('token') }
      );

      setUserType('SUBSCRIBED');
      props.setOpenCheck(false);
      props.setOpenSuccess(true);
    } catch (error) {
      // default Value 세팅
      props.setWarningMessage('결제에 실패했습니다. 잠시 후 다시 시도해주세요.');

      if (isAxiosError(error) && error.response.status === 400) {
        if (error.response.data.name === 'WRONG_CARD_INFO') {
          props.setWarningMessage('카드 정보가 잘못 입력되었습니다.');
        }
        if (error.response.data.name === 'PAYMENT_CERT_FAILED') {
          props.setWarningMessage('카드 본인 확인에 실패했습니다. 사업자등록번호 혹은 생년월일을 확인해주세요.');
        }
        if (error.response.data.name === 'INSUFFICIENT_BALANCE') {
          props.setWarningMessage('잔액부족입니다.');
        }
      }

      props.setOpenWarning(true);
      props.setOpenCheck(false);
    } finally {
      isRequested.current = false;
    }
  };

  const onClickClose = () => {
    props.setOpenCheck(false);
  };

  return (
    <ModalContainer>
      <Title>결제를 신청하시겠습니까?</Title>
      <Description>
        카드 유효성을 확인하기 위해 1원이 자동 결제 후 즉시 취소되며,{'\n'}
        서비스 이용료는 카드 유효성 확인 후 곧바로 결제됩니다.
      </Description>
      <ButtonArea>
        <Button onClick={onClickOrder}>결제</Button>
        <Button
          onClick={onClickClose}
          style={{
            backgroundColor: '#eaebed',
            color: '#97989a'
          }}
        >
          취소
        </Button>
      </ButtonArea>
    </ModalContainer>
  );
}

const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 12px;
  z-index: 999;
  padding: 24px;
`;

const Title = styled.div`
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 10px;
`;

const Description = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: rgba(91, 92, 94, 1);
  margin-top: 16px;
  white-space: pre-line;
  line-height: 25.6px;
`;

const ButtonArea = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
`;

const Button = styled.div`
  width: 190px;
  height: 52px;
  border: none;
  background-color: rgba(50, 144, 255, 1);
  border-radius: 7px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default OrderCheck;
