export const COMMON_MESSAGE = {
  SUCCESS: '정상적으로 처리 되었습니다.',
  SERVER_ERROR: '서버에서 오류가 발생했습니다. 잠시 후 다시 시도해주세요.',
  UNKNOWN_ERROR: '알 수 없는 오류가 발생했습니다:( 다시 시도해주세요',
  BAD_REQUEST: '잘못된 요청입니다. 다시 시도해주세요.',
  FAIL: '처리 중 오류가 발생했습니다.',
  UPDATE_SUCCESS: '수정이 완료되었습니다.',
  UPDATE_FAIL: '수정에 실패했습니다. 다시 시도해 주세요!'
}

export const AUTH_MESSAGE = {
  PASSWORD_NOT_VALID: '현재 비밀번호가 일치하지 않습니다.',
  NEW_PASSWORD_NOT_MATCH: '새로운 비밀번호가 일치하지 않습니다.',
  NEW_PASSWORD_NOT_VALID: '비밀번호는 영문, 숫자(특수문자) 조합 8자리 이상이어야 합니다.',
  PASSWORD_CHANGE_SUCCESS: '비밀번호가 변경되었습니다. 다시 로그인해주세요.'
}
export const WRITING_MESSAGE = {
  NO_AUTH: '로그인이 필요한 기능입니다!',
  NO_CONTENT: '내용을 입력해주세요!',
  WRONG_SENTENCE_ENDING: '완결된 문장 끝에 [. 또는 ! 또는 ?] 중 하나를 넣어 주세요.',
  REACH_MAX_TOKEN: '토큰 사용량이 100%를 초과했습니다.'
}
