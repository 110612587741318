import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import logo from "../../../asset/mainLogoWhite.png";

export default function Sidebar() {
    const navigate = useNavigate();

    //현재 주소의 파라미터가 무엇인지 확인
    const [menu, setMenu] = useState('');

    return(
        <Container>
            <Logo>
                <img 
                    src={logo} 
                    alt="logo" 
                    style={{
                        width: "155.65px",
                        height: "auto"
                    }}
                    onClick={() => navigate('/admin')}
                />
            </Logo>
            <Content>
                <Menu>
                    서비스 관리
                </Menu>
                <Menu onClick={() => navigate('/admin/user')}>
                    가입자 관리
                </Menu>
                <Menu 
                    onClick={() => setMenu('plan')}
                    style={{
                        height: menu === 'plan' ? '132px' : '42px',
                    }}
                >
                    홈페이지 관리
                    {menu === 'plan' ? 
                        <DetailMenu>
                            <DetailMenuText
                                onClick={() => navigate('/admin/faq')}
                            >
                                FAQ 관리
                            </DetailMenuText>
                            <DetailMenuText
                                onClick={() => navigate('/admin/plan')}
                            >
                                요금제 관리
                            </DetailMenuText>
                        </DetailMenu>
                    : null}
                </Menu>
            </Content>
        </Container>
    )
}

const Container = styled.div`
    width: 270px;
    height: 100vh;
    background-color: #3e3e3e;
`;

const Logo = styled.div`
    width: 100%;
    height: 55px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-bottom: 1px solid black;
`;

const Content = styled.div`
    width: 100%;
    height: calc(100vh - 56px);
`;

const Menu = styled.div`
    width: 100%;
    height: 42px;
    cursor: pointer;
    border-bottom: 1px solid #696969;
    color: white;
    font-size: 15px;
    display: flex;
    padding-left: 20px;
    flex-direction: column;
    justify-content: center;
`;

const DetailMenu = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    right: 20px;
    position: relative;
`;

const DetailMenuText = styled.div`
    width: calc(100% - 40px);
    height: 42px;
    cursor: pointer;
    color: white;
    font-size: 15px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    &:hover {
        background-color: #262626;
    }
`;