import React from 'react';
import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import Sidebar from './Components/Sidebar';
import Calendar from './Components/Calendar';
import Paging from './Components/Paging';

import MemberInfo from './Modal/UserInfoModal';
import calendarIcon from '../../asset/calendar.png';
import { UseGetUser } from '../../services/AdminUser';

export default function UserList() {
  const calendarRef = useRef();

  const [isCalendarOpen, setIsCalendarOpen] = useState(false); // 달력 모달
  const [isInfoOpen, setIsInfoOpen] = useState(false); // 고객 정보 상세 모달
  const [date, setDate] = useState('start'); // start or end
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
  //   const [userList, setUserList] = useState([]) // 유저 리스트
  //   const [totalElements, setTotalElements] = useState(0) // 유저 리스트 총 개수
  const [userId, setUserId] = useState(0); // 유저 정보 상세 모달에 넘겨줄 유저 id

  //검색필터
  const [currentType, setCurrentType] = useState(''); // 현재 가입 유형
  const [currentStatus, setCurrentStatus] = useState('ALL'); // 현재 상태
  //
  const [normal, setNormal] = useState(false); // 정상
  const [stop, setStop] = useState(false); // 구독중지

  const [currentCustomer, setCurrentCustomer] = useState(''); // 현재 고객 대상
  const [dateType, setDateType] = useState('CREATED_AT'); // 날짜 타입
  const [startDate, setStartDate] = useState(''); // 시작일
  const [endDate, setEndDate] = useState(''); // 종료일
  const [currentKeyword, setCurrentKeyword] = useState(''); // 현재 검색어

  const [clickCoordinates, setClickCoordinates] = useState({ x: 0, y: 0 }); // 달력용 클릭한 좌표

  const [params, setParams] = useState({ page: currentPage - 1, size: 10 });
  const { data: response } = UseGetUser({ auth: localStorage.getItem('admin_Token') }, params);
  const userList = response?.data.userList ?? [];
  const totalElements = response?.data.totalElement ?? 0;
  //   const fetchData = async () => {
  //     try {
  //       const res = await UseGetApi(
  //         '/admin/user',
  //         { auth: localStorage.getItem('admin_Token') },
  //         { page: currentPage - 1, size: 10 }
  //       )
  //       setUserList(res.data.userList)
  //       setTotalElements(res.data.totalElement)
  //     } catch (err) {
  //       console.error(err)
  //     }
  //   }

  //   useEffect(() => {
  //     fetchData()
  //   }, [currentPage])

  useEffect(() => {
    if (normal && stop) {
      setCurrentStatus('ALL');
    } else if (normal) {
      setCurrentStatus('SUBSCRIBED');
    } else if (stop) {
      setCurrentStatus('NOT_SUBSCRIBED');
    } else {
      setCurrentStatus('');
    }
  }, [normal, stop]);

  //달력 모달 위치 설정
  const handleClick = e => {
    const x = e.clientX;
    const y = e.clientY;
    setClickCoordinates({ x, y });
  };

  //달력 모달 열기
  const onClickCalendar = type => {
    setIsCalendarOpen(!isCalendarOpen);
    setDate(type);
  };

  const setPage = page => {
    setCurrentPage(page);
    setParams({ ...params, page: page - 1 });
  };

  const onDateInputChange = (date, type) => {
    // 날짜 직접 입력 로직 추가
    if (type === 'start') {
      setStartDate(date);
    } else if (type === 'end') {
      setEndDate(date);
    }
  };

  const onClickSearch = async () => {
    const currentParams = {
      page: currentPage - 1,
      size: 10,
      planName: currentType,
      status: currentStatus,
      userType: currentCustomer,
      periodSearchType: dateType,
      keyword: currentKeyword
    };

    if (startDate) {
      currentParams.startDate = moment(startDate).format('YYYY-MM-DD');
    }
    if (endDate) {
      currentParams.endDate = moment(endDate).format('YYYY-MM-DD');
    }
    setParams(currentParams);
    setCurrentPage(1);
    // console.log(currentType, currentStatus, currentCustomer, dateType, start, end, currentKeyword)
    // try {
    //   const res = await UseGetApi(
    //     '/admin/user',
    //     { auth: localStorage.getItem('admin_Token') },
    //     {
    //       page: currentPage - 1,
    //       size: 10,
    //       planName: currentType,
    //       status: currentStatus,
    //       userType: currentCustomer,
    //       periodSearchType: dateType,
    //       startDate: start,
    //       endDate: end,
    //       keyword: currentKeyword
    //     }
    //   )
    //   setUserList(res.data.userList)
    //   setTotalElements(res.data.totalElement)
    // } catch (err) {
    //   console.error(err)
    // }
  };

  return (
    <div>
      <Container>
        <Sidebar />
        <InnerContainer>
          <Title>가입자 관리</Title>
          <MenuArea onClick={handleClick}>
            <Left>
              <Line>
                <ButtonArea
                  style={{
                    marginLeft: '0px'
                  }}
                >
                  <Text>가입 유형</Text>
                  <SelectBox onChange={e => setCurrentType(e.target.value)}>
                    <option value="">전체</option>
                    <option value="GLOT 엔터프라이즈">엔터프라이즈</option>
                    <option value="GLOT 베이직">베이직</option>
                  </SelectBox>
                </ButtonArea>
                <ButtonArea>
                  <Text
                    style={{
                      width: '50px'
                    }}
                  >
                    상태
                  </Text>
                  <CheckBox>
                    <input type="checkbox" checked={normal} onChange={() => setNormal(!normal)} />
                    <span>정상</span>
                    <input type="checkbox" checked={stop} onChange={() => setStop(!stop)} />
                    <span>구독중지</span>
                  </CheckBox>
                </ButtonArea>
              </Line>
              <Line>
                <ButtonArea
                  style={{
                    marginLeft: '0px'
                  }}
                >
                  <Text>고객대상</Text>
                  <SelectBox onChange={e => setCurrentCustomer(e.target.value)}>
                    <option value="">전체</option>
                    <option value="organization">기업</option>
                    <option value="personal">개인</option>
                  </SelectBox>
                </ButtonArea>
                <ButtonArea>
                  <SelectBox
                    style={{
                      width: '120px',
                      marginRight: '10px'
                    }}
                    onChange={e => setDateType(e.target.value)}
                  >
                    <option value="CREATED_AT">가입일자</option>
                    <option value="LAST_LOGIN_AT">최종 접속 일자</option>
                  </SelectBox>
                  <Input
                    value={startDate ? moment(startDate).format('YYYY-MM-DD') : ''}
                    onChange={e => onDateInputChange(e.target.value, 'start')}
                    placeholder="YYYYMMDD"
                    maxLength={8}
                  />
                  <DateButton onClick={() => onClickCalendar('start')} ref={calendarRef}>
                    <img src={calendarIcon} alt="calendar" style={{ width: '100%', height: '100%' }} />
                  </DateButton>
                  <Text
                    style={{
                      marginLeft: '10px',
                      width: '10px'
                    }}
                  >
                    ~
                  </Text>
                  <Input
                    value={endDate ? moment(endDate).format('YYYY-MM-DD') : ''}
                    onChange={e => onDateInputChange(e.target.value, 'end')}
                    placeholder="YYYYMMDD"
                    maxLength={8}
                  />
                  <DateButton onClick={() => onClickCalendar('end')} ref={calendarRef}>
                    <img src={calendarIcon} alt="calendar" style={{ width: '100%', height: '100%' }} />
                  </DateButton>
                </ButtonArea>
                <ButtonArea style={{ flex: 1, marginRight: 10 }}>
                  <Text>검색어</Text>
                  <Input
                    style={{
                      display: 'flex',
                      flex: 1,
                      maxWidth: '200px'
                      //   minWidth: '200px'
                    }}
                    onChange={e => setCurrentKeyword(e.target.value)}
                    onKeyDown={e => {
                      if (e.key === 'Enter') {
                        onClickSearch();
                      }
                    }}
                  />
                </ButtonArea>
              </Line>
            </Left>
            <Right>
              <SearchButton onClick={onClickSearch}>검색</SearchButton>
            </Right>
          </MenuArea>
          <ChartArea>
            <Table>
              <thead>
                <tr>
                  <th>No</th>
                  <th>아이디</th>
                  <th>고객유형</th>
                  <th>고객명(기관명)</th>
                  <th>이메일주소</th>
                  <th>전화번호</th>
                  <th>휴대전화번호</th>
                  <th>가입유형</th>
                  <th>가입일자</th>
                  <th>최종 접속 일자</th>
                  <th>상태</th>
                  <th>토큰 사용량</th>
                </tr>
              </thead>
              <tbody>
                {userList.length === 0 ? (
                  <tr>
                    <td colSpan="12">데이터가 없습니다.</td>
                  </tr>
                ) : (
                  userList.map((user, index) => (
                    <tr
                      key={index}
                      onClick={() => {
                        setIsInfoOpen(!isInfoOpen);
                        setUserId(user.id);
                      }}
                    >
                      <td>{(currentPage - 1) * 10 + index + 1}</td>
                      <td>{user.accountId}</td>
                      <td>{user.userType}</td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      <td>{user.mobile}</td>
                      <td>{user.planName ? user.planName : '무료'}</td>
                      <td>{user.createdAt}</td>
                      <td>{user.lastLoginAt}</td>
                      <td>{user.status}</td>
                      <td>
                        {Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(
                          user.amountTokenUsed
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            <Paging page={currentPage} count={totalElements} setPage={setPage} />
          </ChartArea>
        </InnerContainer>
      </Container>
      {isCalendarOpen && (
        <Calendar
          setIsOpen={setIsCalendarOpen}
          clickCoordinates={clickCoordinates}
          date={date === 'start' ? startDate : endDate}
          setDate={date === 'start' ? setStartDate : setEndDate}
        />
      )}
      {isInfoOpen && <MemberInfo isOpen={isInfoOpen} setIsOpen={setIsInfoOpen} id={userId} />}
    </div>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: row;
  background-color: #fff;
`;

const InnerContainer = styled.div`
  width: calc(100% - 270px);
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
`;

const Title = styled.div`
  width: calc(100% - 42px);
  color: rgb(17, 17, 17, 1);
  padding: 21px;
  font-size: 21px;
  font-weight: bold;
`;

const MenuArea = styled.div`
  width: calc(100% - 84px);
  height: 93px;
  margin: 0px 21px;
  padding: 0px 21px;
  display: flex;
  flex-direction: row;
  background-color: #ececec;
`;

const Left = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  content-align: center;
  justify-content: space-evenly;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  content-align: center;
  justify-content: center;
  align-items: flex-end;
  padding: 12px 0px;
`;

const Line = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  content-align: center;
  justify-content: flex-start;
`;

const ButtonArea = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  content-align: center;
  margin-left: 30px;
`;

const Text = styled.div`
  width: 60px;
  color: rgb(17, 17, 17, 1);
  font-size: 15px;
  font-weight: bold;
  margin-right: 10px;
  display: flex;
  align-items: center;
`;

const CheckBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  content-align: center;
  align-items: center;

  input {
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }

  span {
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
  }
`;

const Input = styled.input`
  width: 90px;
  padding-left: 10px;
  color: rgb(17, 17, 17, 1);
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DateButton = styled.div`
  width: 30px;
  height: 30px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchButton = styled.button`
  width: 74px;
  height: 100%;
  color: #fff;
  background-color: #a8a8a8;
  border: none;
  font-size: 15px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ChartArea = styled.div`
  width: calc(100% - 42px);
  height: 100%;
  padding: 21px;
`;

const SelectBox = styled.select`
  width: 130px;
  color: rgb(17, 17, 17, 1);
  font-size: 14px;
  font-weight: bold;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-top: 1px solid black;
  text-align: center;
  font-size: 12px;
  color: rgb(17, 17, 17, 1);
  margin-top: 10px;
  margin-bottom: 10px;

  th {
    height: 42px;
    border-bottom: 1px solid black;
  }

  td {
    height: 42px;
    border-bottom: 1px solid #e5e5e5;
  }
`;
