import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Logo from "../../asset/mainLogoBlack.png";
import close from "../../asset/closeModal.png";

export default function MainMenu(props) {
    const navigate = useNavigate();
    
    const onClickMenu = (menu) => {
        if(menu === 'About') {
            navigate('/');
        } else if(menu === 'GLOT AI') {
            navigate('/glotai');
        } else if(menu === 'Pricing') {
            navigate('/pricing');
        }
    }

    return (
        <MainMenuContainer>
            <LogoArea>
                <LogoIcon src={Logo} />
                <CloseButton
                    src={close}
                    alt="close"
                    onClick={() => props.setIsMenuOpen(false)}
                />
            </LogoArea>
            <MainMenuContent>
                <MainMenuText onClick={() => onClickMenu('About')}>
                    GLOT과 AI
                </MainMenuText>
                <MainMenuText onClick={() => onClickMenu('GLOT AI')}>
                    GLOT Writing
                </MainMenuText>
                <MainMenuText onClick={() => onClickMenu('Pricing')}>
                    Pricing
                </MainMenuText>
            </MainMenuContent>
        </MainMenuContainer>
    );
}

const MainMenuContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    background-color: #FFFFFF;
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
`;

const LogoArea = styled.div`
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const LogoIcon = styled.img`
    width: 155.65px;
    height: 20.27px;
    margin-left: 19px;
    margin-top: 9px;
`;

const CloseButton = styled.img`
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20px;
    cursor: pointer;
`;

const MainMenuContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const MainMenuText = styled.div`
    width: 100%;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    padding-left: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    &:hover {
        background-color: rgba(242, 243, 245, 1);
    }
`;
