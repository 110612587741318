// 작문 추천 API

import axios from 'axios';
import { BASE_URL } from '../constants/Url';
import { customNotify } from '../util/customNotify';
import { COMMON_MESSAGE, WRITING_MESSAGE } from '../constants/CustomMessage';
import { getTokenRate } from './tokenRate';
import { handleTokenRateNotification } from '../util/tokenRateNotification';
import { handleTokenExpired } from '../util/handleTokenExpired';

const SentenceRecomendApi = async (sentence, type) => {
  try {
    const response = await axios.post(`${BASE_URL}/help/writing`, {
      sentence: sentence,
      type: type
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export default SentenceRecomendApi;

export const getSentences = async (
  sentence,
  sentenceType,
  handleSentenceSet,
  handleLoading,
  controller,
  isAdmin,
  isVip
) => {
  const sentenceTemp = new Array(4).fill(0).map(() => []);

  const token = localStorage.getItem('token');
  const API_URL = `${BASE_URL}/help/writing?auth=${token}&sentence=${encodeURIComponent(
    sentence
  )}&type=${sentenceType}`;
  let loading = true;
  const handleLoadingOnce = () => {
    if (loading) {
      loading = false;
      handleLoading();
    }
  };
  try {
    const response = await fetch(API_URL, {
      method: 'GET',
      signal: controller.signal
    });
    if (!response.ok) {
      if (response.status === 400) {
        try {
          const tokenRate = await getTokenRate(token);
          if (tokenRate >= 1) {
            throw new Error(WRITING_MESSAGE.REACH_MAX_TOKEN, { cause: 400 });
          } else throw new Error(COMMON_MESSAGE.BAD_REQUEST, { cause: 400 });
        } catch (e) {
          if (e.response && e.response.status === 401) {
            throw new Error(COMMON_MESSAGE.SERVER_ERROR, { cause: 401 });
          }
          throw e;
        }
      }
      if (response.status === 401) {
        throw new Error(COMMON_MESSAGE.SERVER_ERROR, { cause: 401 });
      } else if (response.status === 500) {
        throw new Error(COMMON_MESSAGE.UNKNOWN_ERROR, { cause: 404 });
      }
    }
    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
    while (reader) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
      const chunk = decoder.decode(value);
      const jsonStrings = chunk.split('data:').filter(s => s.trim() !== '');
      jsonStrings.forEach(jsonStr => {
        if (jsonStr.trim().includes('DONE')) {
          // console.log('스트림 종료')
          return;
        }
        if (jsonStr.trim().includes('token rate')) {
          handleTokenRateNotification(jsonStr, isAdmin, isVip);
          return;
        }
        try {
          const match = jsonStr.match(/\d+/);
          const index = match ? parseInt(match[0]) : null;
          const result = match ? jsonStr.substring(match[0].length + 1).replace(/\n/g, '') : jsonStr;
          // console.log(index, result)
          if (index !== null && result) {
            sentenceTemp[index].push(result.replace(/[<>]/g, ' '));
            handleSentenceSet(index, sentenceTemp[index].join(''));
            handleLoadingOnce();
          }
        } catch (e) {
          customNotify(COMMON_MESSAGE.UNKNOWN_ERROR, 'error');
        }
      });
    }
  } catch (e) {
    if (e.name === 'AbortError') return;
    if (e.cause === 401) {
      handleTokenExpired();
      return;
    }

    customNotify(e.message, 'error');
  } finally {
    controller.abort();
  }
};
