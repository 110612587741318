import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import NoResultModal from '../../components/Modal/NoResultModal';
import Backdrop from '../../components/Modal/Backdrop';

import { UsePostApi } from '../../services/http';

import logo from '../../asset/GLOT logo.png';
import { validatePhoneNumber } from '../../util/validate';
import { isAxiosError } from 'axios';

export default function ChangePw() {
  const navigate = useNavigate();

  const [userId, setUserId] = useState(''); //아이디
  const [userName, setUserName] = useState(''); //이름

  const [userMobile, setUserMobile] = useState(''); //휴대폰번호
  const [isSendedMobileCode, setIsSendedMobileCode] = useState(false); //휴대폰 인증 번호 전송 여부
  const [userMobileCode, setUserMobileCode] = useState(''); //인증번호

  const [isModal, setIsModal] = useState(false); //일치하는 정보없음 모달
  const [isInputCompleted, setIsInputCompleted] = useState(false); //모든 입력이 완료되었는지 여부
  const [isAuth, setIsAuth] = useState(false); //인증번호 확인 여부

  const checkInput = () => {
    if (userId === '' || userName === '' || userMobile === '' || userMobileCode === '') {
      setIsInputCompleted(false);
    } else {
      setIsInputCompleted(true);
    }
  };

  useEffect(() => {
    checkInput();
  }, [userId, userName, userMobile, userMobileCode]);

  const onClickAuth = async () => {
    if (!window.confirm(`${userMobile}로 인증번호를 전송합니다.`)) {
      return;
    }

    try {
      await UsePostApi('/cert/sms/code', { mobile: userMobile });
      setIsSendedMobileCode(true);
      alert('인증번호가 전송되었습니다.');
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response.status === 400 && error.response.data.name === 'INVALID_PHONE_NUMBER') {
          alert('정확한 휴대 전화번호를 입력해주세요.');
          return;
        }
      }

      alert('인증번호 전송에 실패했습니다. 다시 시도해주세요.');
    }
  };

  const onClickCheckCode = async () => {
    try {
      const response = await UsePostApi(`/cert/sms/verify?code=${userMobileCode}`);
      if (response.data.success) {
        setIsAuth(true);
        alert('인증되었습니다.');
        checkInput();
      } else {
        alert('인증번호가 일치하지 않습니다.');
      }
    } catch (error) {
      alert('인증번호가 일치하지 않습니다.');
    }
  };

  const onClickSearch = async () => {
    navigate(`/member/newpw?accountId=${userId}&code=${userMobileCode}`);
  };

  return (
    <Container>
      <InputArea>
        <InnerContainer>
          <LogoArea>
            <Logo src={logo} />
          </LogoArea>
          <Title>비밀번호 변경</Title>
          <InputContainer>
            <Naming>아이디</Naming>
            <InputOuter>
              <Input
                placeholder="아이디를 입력해 주세요"
                type="text"
                value={userId}
                onChange={e => setUserId(e.target.value)}
              />
            </InputOuter>
            <Naming>이름</Naming>
            <InputOuter>
              <Input
                placeholder="이름을 입력해 주세요"
                type="text"
                value={userName}
                onChange={e => setUserName(e.target.value)}
              />
            </InputOuter>
            <Naming>휴대 전화번호 (‘-’ 제외)</Naming>
            <InputOuter>
              <Input
                placeholder="휴대폰 전화번호를 입력해 주세요"
                type="text"
                style={{ width: '73%' }}
                value={userMobile}
                onChange={e => {
                  if (!validatePhoneNumber(e.target.value)) {
                    return;
                  }

                  setUserMobile(e.target.value);
                }}
              />
              <InputButton onClick={onClickAuth} disabled={userMobile.length !== 11}>
                인증
              </InputButton>
            </InputOuter>
            <Naming>인증 번호</Naming>
            <InputOuter>
              <Input
                placeholder="인증 번호"
                type="text"
                style={{ width: '73%' }}
                value={userMobileCode}
                onChange={e => {
                  if (e.target.value.length > 6) {
                    return;
                  }

                  setUserMobileCode(e.target.value);
                }}
                disabled={!isSendedMobileCode}
              />
              <InputButton onClick={onClickCheckCode} disabled={userMobileCode.length !== 6}>
                확인
              </InputButton>
            </InputOuter>
          </InputContainer>
          <ButtonArea>
            <Button
              style={{
                backgroundColor: isAuth ? '#3290ff' : '#eaebed',
                color: isAuth ? 'white' : '#97989a'
              }}
              onClick={onClickSearch}
              disabled={!isAuth || !isInputCompleted}
            >
              변경
            </Button>
            <Button onClick={() => navigate('/login')} style={{ backgroundColor: '#eaebed', color: '#97989a' }}>
              취소
            </Button>
          </ButtonArea>
        </InnerContainer>
      </InputArea>
      {isModal && <NoResultModal setIsModal={setIsModal} />}
      {isModal && <Backdrop setIsModal={setIsModal} />}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InputArea = styled.div`
  width: 700px;
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const InnerContainer = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const LogoArea = styled.div`
  width: 100%;
  height: 192px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
    height: 120px;
  }
`;

const Logo = styled.img`
  width: 127px;
  height: 56px;
`;

const Title = styled.div`
  width: 100%;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.5px;
  text-align: center;
  white-space: pre-line;
  @media (max-width: 768px) {
    font-size: 24px;
    font-weight: 600;
  }
`;

const InputContainer = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
  @media (max-width: 768px) {
    width: 327px;
  }
`;

const Naming = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #6f7071;
  padding-left: 3px;
`;

const InputOuter = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 28px;
  margin-top: 5px;
`;

const Input = styled.input`
  width: 385px;
  height: 48px;
  border: 1px solid rgba(234, 235, 237, 1);
  border-radius: 10px;
  font-size: 13px;
  font-weight: 400;
  padding-left: 15px;
  ::placeholder {
    color: rgba(183, 184, 186, 1);
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const InputButton = styled.button`
  width: 80px;
  height: 100%;
  border: none;
  border-radius: 8px;
  background-color: #111111;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;

  &:disabled {
    background-color: #f2f3f5;
    color: #97989a;
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    margin-left: 5px;
    height: 56px;
  }
`;

const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 4px;
  margin-bottom: 46px;
  @media (max-width: 768px) {
    width: 327px;
    margin-bottom: 100px;
  }
`;

const Button = styled.button`
  width: 195px;
  height: 50px;
  border: none;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 155px;
  }
`;
