import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { UseGetApi, UsePatchApi } from '../../../services/http';

export default function UserInfo(props) {
  const [page, setPage] = useState(1);
  const [user, setUser] = useState({});
  const [payment, setPayment] = useState({});
  const [purchaseUser, setPurchaseUser] = useState([]);
  const [editing, setEditing] = useState(false);

  const onClickClose = () => {
    props.setIsOpen(false);
  };

  const fetchData = async () => {
    try {
      const res = await UseGetApi(`/admin/user/${props.id}/info`, { auth: localStorage.getItem('admin_Token') });
      setUser(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const paymentData = async () => {
    try {
      const res = await UseGetApi(`/admin/user/${props.id}/orders`, { auth: localStorage.getItem('admin_Token') });
      setPayment(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const purchaseUserData = async () => {
    try {
      const res = await UseGetApi(`/admin/user/${props.id}/members`, { auth: localStorage.getItem('token') });
      setPurchaseUser(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (page === 2) {
      paymentData();
    } else if (page === 3) {
      purchaseUserData();
    }
  }, [page]);

  const onClickModify = async () => {
    try {
      await UsePatchApi(
        `/admin/user/${props.id}/info`,
        { name: user.name, email: user.email, phone: user.phone, mobile: user.mobile },
        { auth: localStorage.getItem('admin_Token') }
      );
      alert('수정되었습니다.');
      setEditing(true);
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };

  const onClickStop = () => {
    window.confirm(
      '해당 계정의 서비스 이용 정지 하시겠습니까?\n서비스 이용을 정지할 경우 해당 계정과 함께 구매된 계정의 사용이 정지됩니다.'
    ) && stopUser(user.accountId);
  };

  const stopUser = async id => {
    try {
      await UsePatchApi(
        '/admin/user/activate',
        { accountId: id, active: false },
        { auth: localStorage.getItem('admin_Token') }
      );
      alert('정지되었습니다.');
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };

  const onClickActivate = () => {
    window.confirm('해당 계정의 서비스 이용을 정지 해제 하시겠습니까?') && activateUser(user.accountId);
  };

  const activateUser = async id => {
    try {
      await UsePatchApi(
        '/admin/user/activate',
        { accountId: id, active: true },
        { auth: localStorage.getItem('admin_Token') }
      );
      alert('정지가 해제되었습니다.');
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <Icon onClick={onClickClose}>x</Icon>
      <Title>고객명</Title>
      <Content>
        <Menu>
          <MenuItem
            onClick={() => setPage(1)}
            style={{
              borderBottom: page === 1 ? '2px solid #3290ff' : '',
              color: page === 1 ? '#3290ff' : ''
            }}
          >
            <MenuText>회원정보</MenuText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (user.status === 'FREE') {
                alert('무료 회원은 결제 정보가 없습니다.');
              } else {
                setPage(2);
              }
            }}
            style={{
              borderBottom: page === 2 ? '2px solid #3290ff' : '',
              color: page === 2 ? '#3290ff' : ''
            }}
          >
            <MenuText>결제정보</MenuText>
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (user.planName !== 'GLOT 엔터프라이즈') {
                alert('GLOT 엔터프라이즈 회원만 구매 계정 정보를 확인할 수 있습니다.');
              } else {
                setPage(3);
              }
            }}
            style={{
              borderBottom: page === 3 ? '2px solid #3290ff' : '',
              color: page === 3 ? '#3290ff' : ''
            }}
          >
            <MenuText>구매 계정 정보</MenuText>
          </MenuItem>
        </Menu>
        <Border />
        {page === 1 && (
          <Info>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <InfoTitle>회원 기본 정보</InfoTitle>
              {editing ? (
                <Button onClick={onClickModify}>[저장]</Button>
              ) : (
                <Button onClick={() => setEditing(true)}>[정보수정]</Button>
              )}
            </div>
            {editing ? (
              <InfoContent>
                <InfoItem>
                  <InfoItemTitle>아이디</InfoItemTitle>
                  <InfoItemContent>{user.accountId}</InfoItemContent>
                </InfoItem>
                <InfoItem>
                  <InfoItemTitle>고유식별번호</InfoItemTitle>
                  <InfoItemContent>{user.id}</InfoItemContent>
                </InfoItem>
                <InfoItem>
                  <InfoItemTitle>고객유형</InfoItemTitle>
                  <InfoItemContent>{user.userType}</InfoItemContent>
                </InfoItem>
                <InfoItem>
                  <InfoItemTitle>고객명</InfoItemTitle>
                  <InfoItemContent>
                    <Input value={user.name} onChange={e => setUser({ ...user, name: e.target.value })} />
                  </InfoItemContent>
                </InfoItem>
                <InfoItem>
                  <InfoItemTitle>이메일</InfoItemTitle>
                  <InfoItemContent>
                    <Input value={user.email} onChange={e => setUser({ ...user, email: e.target.value })} />
                  </InfoItemContent>
                </InfoItem>
                <InfoItem>
                  <InfoItemTitle>전화번호</InfoItemTitle>
                  <InfoItemContent>
                    <Input value={user.phone} onChange={e => setUser({ ...user, phone: e.target.value })} />
                  </InfoItemContent>
                </InfoItem>
                <InfoItem>
                  <InfoItemTitle>휴대전화번호</InfoItemTitle>
                  <InfoItemContent>
                    <Input value={user.mobile} onChange={e => setUser({ ...user, mobile: e.target.value })} />
                  </InfoItemContent>
                </InfoItem>
              </InfoContent>
            ) : (
              <InfoContent>
                <InfoItem>
                  <InfoItemTitle>아이디</InfoItemTitle>
                  <InfoItemContent>{user.accountId}</InfoItemContent>
                </InfoItem>
                <InfoItem>
                  <InfoItemTitle>고유식별번호</InfoItemTitle>
                  <InfoItemContent>{user.id}</InfoItemContent>
                </InfoItem>
                <InfoItem>
                  <InfoItemTitle>고객유형</InfoItemTitle>
                  <InfoItemContent>{user.userType}</InfoItemContent>
                </InfoItem>
                <InfoItem>
                  <InfoItemTitle>고객명</InfoItemTitle>
                  <InfoItemContent>{user.name}</InfoItemContent>
                </InfoItem>
                <InfoItem>
                  <InfoItemTitle>이메일</InfoItemTitle>
                  <InfoItemContent>{user.email}</InfoItemContent>
                </InfoItem>
                <InfoItem>
                  <InfoItemTitle>전화번호</InfoItemTitle>
                  <InfoItemContent>{user.phone}</InfoItemContent>
                </InfoItem>
                <InfoItem>
                  <InfoItemTitle>휴대전화번호</InfoItemTitle>
                  <InfoItemContent>{user.mobile}</InfoItemContent>
                </InfoItem>
              </InfoContent>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <InfoTitle>회원 가입 정보</InfoTitle>
              {user.active ? (
                <Button onClick={onClickStop} style={{ color: '#ff0000' }}>
                  사용 정지
                </Button>
              ) : (
                <Button onClick={onClickActivate} style={{ color: '#3290ff' }}>
                  정지 해제
                </Button>
              )}
            </div>
            <InfoContent>
              <InfoItem>
                <InfoItemTitle>가입유형</InfoItemTitle>
                <InfoItemContent>{user.planName ? user.planName : '무료'}</InfoItemContent>
              </InfoItem>
              <InfoItem>
                <InfoItemTitle>가입일자</InfoItemTitle>
                <InfoItemContent>{user.createdAt}</InfoItemContent>
              </InfoItem>
              <InfoItem>
                <InfoItemTitle>최종 접속 일시</InfoItemTitle>
                <InfoItemContent>{user.lastLoginAt}</InfoItemContent>
              </InfoItem>
              <InfoItem>
                <InfoItemTitle>상태</InfoItemTitle>
                <InfoItemContent>{user.status}</InfoItemContent>
              </InfoItem>
              <InfoItem>
                <InfoItemTitle>토큰 사용량</InfoItemTitle>
                <InfoItemContent>
                  {Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(user.amountTokenUsed)}
                </InfoItemContent>
              </InfoItem>
            </InfoContent>
            <InfoTitle>GLOT AI 사용 여부</InfoTitle>
            <InfoContent>
              {user.usingAI ? (
                <InfoItem
                  style={{
                    justifyContent: 'center',
                    padding: '8px 0px'
                  }}
                >
                  있음
                </InfoItem>
              ) : (
                <InfoItem
                  style={{
                    justifyContent: 'center',
                    padding: '8px 0px'
                  }}
                >
                  없음
                </InfoItem>
              )}
            </InfoContent>
          </Info>
        )}
        {page === 2 && (
          <Info>
            <InfoTitle>결제 정보</InfoTitle>
            <InfoItem>
              <InfoItemTitle>결제일</InfoItemTitle>
              <InfoItemContent>{payment.firstPaidDate}</InfoItemContent>
            </InfoItem>
            <InfoItem>
              <InfoItemTitle>결제방법</InfoItemTitle>
              <InfoItemContent>{payment.payMethod}</InfoItemContent>
            </InfoItem>
            <InfoItem>
              <InfoItemTitle>다음 결제일</InfoItemTitle>
              <InfoItemContent>{payment.nextPayDate}</InfoItemContent>
            </InfoItem>
            <InfoTitle>결제 상세 내역</InfoTitle>
            <Table>
              <thead>
                <tr>
                  <th>출금일</th>
                  <th>서비스 이용기간</th>
                  <th>결제수단정보</th>
                  <th>결제 금액</th>
                </tr>
              </thead>
              <tbody>
                {payment.history ? (
                  payment.history.map((pay, index) => (
                    <tr key={index}>
                      <td>{pay.paidDate}</td>
                      <td>{pay.servicePeriod}</td>
                      <td>
                        {payment.payMethod} {pay.cardNumber}
                      </td>
                      <td>{pay.amount + pay.surtax}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} style={{ textAlign: 'center' }}>
                      조회된 내역이 없습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Info>
        )}
        {page === 3 && (
          <Info>
            <InfoTitle>구매 계정 정보</InfoTitle>
            <Table>
              <thead>
                <tr>
                  <th>번호</th>
                  <th>아이디</th>
                  <th>이름</th>
                  <th>마지막 접속 기록</th>
                </tr>
              </thead>
              <tbody>
                {purchaseUser.map((user, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{user.accountId}</td>
                    <td>{user.name}</td>
                    <td>{user.lastLog}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Info>
        )}
      </Content>
      <BottomArea>
        <BottomButton onClick={onClickClose}>닫기</BottomButton>
      </BottomArea>
    </Container>
  );
}

const Container = styled.div`
  width: 700px;
  max-height: 600px;
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid black;
  overflow: auto;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding: 16px;
  background-color: #3e3e3e;
  color: #ffffff;
`;

const Menu = styled.div`
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e5e5e5;
`;

const MenuItem = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const MenuText = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const Content = styled.div`
  width: calc(100% - 48px);
  height: 100%;
  padding: 24px;
`;

const Icon = styled.div`
  width: 27px;
  height: 27px;
  font-size: 24px;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 12px;
  right: 6px;
`;

const Button = styled.div`
  width: 70px;
  color: #3290ff;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  margin-top: 32px;
  cursor: pointer;
`;

const Info = styled.div`
  width: 100%;
  padding: 16px 0px;
`;

const InfoTitle = styled.div`
  font-size: 16px;
  font-weight: 700;
  padding: 8px;
  margin-top: 24px;
  margin-bottom: 8px;
`;

const InfoContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InfoItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid #dfdfdf;
  border-collapse: collapse;
`;

const InfoItemTitle = styled.div`
  width: 124px;
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
  background-color: #e8e8e8;
`;

const InfoItemContent = styled.div`
  width: calc(100% - 124px);
  padding: 8px;
  font-size: 14px;
  font-weight: 500;
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  font-size: 14px;
  font-weight: 500;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  & th {
    padding: 8px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
  }
  & td {
    padding: 8px;
    border-bottom: 1px solid #dfdfdf;
  }
`;

const BottomArea = styled.div`
  width: calc(100% - 48px);
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  margin-left: 24px;
  border-top: 1px solid #dfdfdf;
`;

const BottomButton = styled.div`
  width: 70px;
  height: 30px;
  color: black;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #dfdfdf;
  border-radius: 5px;
`;
