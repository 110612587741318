import axios, { isAxiosError } from 'axios';
import { BASE_URL } from '../constants/Url';
import { useQuery } from 'react-query';
import { handleTokenExpired } from '../util/handleTokenExpired';

export const getTokenRate = async auth => {
  const response = await axios.get(`${BASE_URL}/user/token-rate`, {
    headers: { auth: auth }
  });
  return response.data;
};

export const useGetTokenRate = auth => {
  return useQuery({
    queryKey: ['tokenRate', auth],
    queryFn: () => getTokenRate(auth),
    enabled: !!auth,
    retry: false,
    onError: error => {
      if (isAxiosError(error) && error.response.status === 401) {
        handleTokenExpired();
        return;
      }

      return Promise.reject(error);
    }
  });
};
