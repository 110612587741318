import React from "react";
import styled from "styled-components";

import { useRecoilState } from "recoil";
import { SentenceType, Sentence } from "../../data/Atom";

export default function WritingOptionModal({ coordinates, setIsSlashTyped, setIsOptionSelected, writingContent, setWritingContent}) {
    const [selectOption, setSelectOption] = useRecoilState(SentenceType); // 선택한 작문 옵션
    const [sentence, setSentence] = useRecoilState(Sentence);
    
    const onClickOption = (option) => {
        setSelectOption(option);
        setIsSlashTyped(false);
        setIsOptionSelected(true);
    }

    const onClickClose = () => {
        const option = '';
        setIsSlashTyped(false);
        setWritingContent(writingContent.slice(0, -1));
        setSentence(option);
        setIsOptionSelected(false);
    }

    return (
        <ModalWrapper coordinates={coordinates}>
            <ModalTitle>
                <Title>
                    유형 선택하기
                </Title>
                <Icon onClick={onClickClose}>
                    X
                </Icon>
            </ModalTitle>
            <ModalContent>
                <SelectContent onClick={() => onClickOption('progress')}>
                    <SelectTitle>발전형</SelectTitle>
                    <SelectDescription>제시된 문장을 이어 가면서 진행시키는 유형</SelectDescription>
                </SelectContent>
                <SelectContent onClick={() => onClickOption('reverse')}>
                    <SelectTitle>반대형</SelectTitle>
                    <SelectDescription>제시된 문장에 반대되는 문장을 만드는 유형</SelectDescription>
                </SelectContent>
                <SelectContent onClick={() => onClickOption('conclusion')}>
                    <SelectTitle>결론형</SelectTitle>
                    <SelectDescription>제시된 문장을 이어 가면서 잠정적인 결론을 만드는 유형</SelectDescription>
                </SelectContent>
            </ModalContent>
        </ModalWrapper>
    );
};

const ModalWrapper = styled.div`
    position: absolute;
    top: ${props => props.coordinates.y}px;
    left: ${props => props.coordinates.x}px;
    width: 298px;
    background-color: #FFFFFF;
    box-shadow: 0px 2px 14px 0px rgba(70, 71, 57, 0.12);
    border: 1px solid #eaebed;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    z-index: 999;
    @media (max-width: 768px) {
        width: calc(100% - 16px);
        height: 290px;
        top: 46vh;
        left: 0px;
        border-top: 1px solid #eaebed;
        border-radius: 0px;
        box-shadow: none;
        background-color: #F8F9FB;
    }
`;

const ModalTitle = styled.div`
    width: calc(100% - 16px);
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: #33B864;
    @media (max-width: 768px) {
        font-size: 15px;
        font-weight: 600;
    }
`;

const Icon = styled.div`
    display: flex;
    color: #33B864;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const ModalContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const SelectContent = styled.div`
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 8px;
    &:hover {
        background: rgba(242, 243, 245, 1);
        border-radius: 6px;
    }
    @media (max-width: 768px) {
        width: calc(100% - 16px);
        padding: 8px;
        border: 1px solid #eaebed;
        border-radius: 6px;
        background-color: #FFFFFF;
        margin-bottom: 8px;
    }
`;

const SelectTitle = styled.div`
    font-size: 16px;
    font-weight: 500;
    color: rgba(91, 92, 94, 1);
    @media (max-width: 768px) {
        font-size: 15px;
        font-weight: 600;
    }
`;

const SelectDescription = styled.div`
    font-size: 13px;
    font-weight: 400;
    color: rgba(151, 152, 154, 1);
    margin-top: 5px;
    @media (max-width: 768px) {
        font-size: 12px;
    }
`;