import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UseGetApi } from '../../services/http';
import { LoginState, UserType } from '../../data/Atom';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import Header from '../../components/Header';
import { customNotify } from '../../util/customNotify';

export default function PricingInfo() {
  const navigate = useNavigate();

  const [plan, setPlan] = useState([]);
  const [userType] = useRecoilState(UserType);
  const [isLogin] = useRecoilState(LoginState);
  const isPersonalUser = localStorage.getItem('role') === 'ROLE_PERSONAL' ? true : false;
  const fetchData = async () => {
    const response = await UseGetApi('/plan');
    setPlan(response.data.splice(0, 3));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container>
      <Header />
      <TextArea>
        <Title>
          가장 합리적인 가격의{'\n'}
          GLOT(General Logic of Text) 인공 지능(AI)으로{'\n'}
          최고가 되어보세요.
        </Title>
      </TextArea>
      <BannerArea>
        {plan.map((item, index) => (
          <Banner key={index}>
            <BannerTitle
              style={{
                backgroundColor: item.id === 1 ? '#E5D544' : item.id === 2 ? '#3290FF' : '#FF6B6B',
                color: 'rgba(255, 255, 255, 1)'
              }}
            >
              {item.name}
            </BannerTitle>
            <BannerPrice>
              {item.id === 1 ? '무료' : new Intl.NumberFormat().format(item.monthlyPrice)}
              {item.id === 1 ? (
                ''
              ) : (
                <Small style={{ fontSize: '28px', lineHeight: '32px', marginBottom: '3px' }}>원</Small>
              )}
              {item.id === 1 ? '' : <Gray>/월</Gray>}
              {item.detail.perUser ? <Gray>(1인기준)</Gray> : <></>}
            </BannerPrice>
            <BannerContent>
              {item.detail.contentList.map((line, index) => (
                <>
                  <BannerContentLine key={index}>
                    <BannerContentTitle>{line.subtitle}</BannerContentTitle>
                  </BannerContentLine>
                  {line.subContentList.map((content, index) => (
                    <BannerContentLine key={index} style={{ marginBottom: '12px' }}>
                      <BannerContentIcon>✔️</BannerContentIcon>
                      <div>
                        <BannerContentText>{content.content}</BannerContentText>
                        <BannerContentText>
                          {content.description ? '(' + content.description + ')' : ''}
                        </BannerContentText>
                      </div>
                    </BannerContentLine>
                  ))}
                </>
              ))}
            </BannerContent>
            <ButtonContent>
              <CheckButton
                style={{
                  backgroundColor: item.id === 1 ? '#E5D544' : item.id === 2 ? '#3290FF' : '#FF6B6B',
                  display: item.id === 1 ? 'none' : 'flex'
                }}
                onClick={() => {
                  if (!isLogin) {
                    alert('로그인 후 이용해주세요.');
                    navigate('/login');
                    return;
                  }
                  if (userType !== 'FREE') {
                    alert('이미 구독 중 입니다.');
                    return;
                  }

                  if (item.id === 2) {
                    !isPersonalUser
                      ? customNotify('개인 회원만 이용하실 수 있습니다.', 'info')
                      : navigate('/payment/basic');
                    window.scrollTo(0, 0);
                  } else if (item.id === 3) {
                    isPersonalUser
                      ? customNotify('기관 회원만 이용하실 수 있습니다.', 'info')
                      : navigate('/payment/enterprise');
                    window.scrollTo(0, 0);
                  }
                }}
              >
                {item.name} 구매하기
              </CheckButton>
            </ButtonContent>
          </Banner>
        ))}
      </BannerArea>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
`;

const TextArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 96px;
`;

const Title = styled.div`
  width: 100%;
  height: 100%;
  font-size: 36px;
  font-weight: 600;
  line-height: 50.4px;
  letter-spacing: -0.03em;
  text-align: center;
  color: rgba(17, 17, 17, 1);
  white-space: pre-line;
`;

const BannerArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 53px;
  margin-bottom: 96px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const Banner = styled.div`
  width: 385px;
  height: 560px;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 1);
  margin: 0 18px;
  box-shadow: 0px 5px 60px 0px rgba(62, 96, 130, 0.16);
  @media (max-width: 768px) {
    margin-top: 48px;
    width: 90%;
  }
`;

const BannerTitle = styled.div`
  width: 100%;
  height: 64px;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  letter-spacing: -0.03em;
  text-align: center;
  color: rgba(17, 17, 17, 1);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BannerPrice = styled.div`
  width: calc(100% - 48px);
  height: 52px;
  padding-top: 16px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: -0.03em;
  text-align: center;
  color: rgba(17, 17, 17, 1);
  display: flex;
  justify-content: flex-start;
  align-items: end;
  border-bottom: 1px solid rgba(242, 243, 245, 1);
  @media (max-width: 768px) {
    font-weight: 500;
  }
`;

const BannerContent = styled.div`
  width: calc(100% - 48px);
  height: 244px;
  padding: 24px;
  padding-top: 12px;
  font-size: 17px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(17, 17, 17, 1);
  white-space: pre-line;
`;

const BannerContentLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 12px;
`;

const BannerContentIcon = styled.div`
  height: 100%;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(17, 17, 17, 1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const BannerContentTitle = styled.div`
  width: calc(100% - 26px);
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(17, 17, 17, 1);
  justify-content: flex-start;
  align-items: center;
  word-break: keep-all;
`;

const BannerContentText = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(17, 17, 17, 1);
  justify-content: flex-start;
  align-items: center;
  margin-left: 8px;
  word-break: keep-all;
`;

const Small = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(17, 17, 17, 1);
`;

const Gray = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.03em;
  color: rgba(195, 202, 211, 1);
  margin-left: 3px;
  margin-bottom: 6px;
`;

const ButtonContent = styled.div`
  width: calc(100% - 48px);
  height: 56px;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckButton = styled.div`
  width: 100%;
  height: 56px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.03em;
  display: flex;
  justify-content: center;
  align-items: center;
`;
