import React from 'react';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import About from './pages/Main/About';
import GlotAi from './pages/Main/GlotAi';
import Pricing from './pages/Main/Pricing';

import Login from './pages/Login/Login';
import Idsearch from './pages/Login/Idsearch';
import ChangePw from './pages/Login/ChangePw';
import NewPw from './pages/Login/NewPw';
import SignUp from './pages/Login/SignUp';
import Writing from './pages/Writing/Writing';

import Mypage from './pages/Mypage/Mypage';
import GroupInfo from './pages/Mypage/GroupInfo';
import PaidInfo from './pages/Mypage/PaidInfo';
import FreeInfo from './pages/Mypage/FreeInfo';
import BasicPrice from './pages/Pricing/BasicPricing';
import EnterprisePrice from './pages/Pricing/EnterprisePricing';
import Payment from './pages/Pricing/Pricing';

import AdminLogin from './pages/Admin/Login';
import MemberList from './pages/Admin/User';
import FAQ from './pages/Admin/FAQ';
import FAQEnroll from './pages/Admin/FAQEnroll';
import Plan from './pages/Admin/Plan';
import { useRecoilState } from 'recoil';
import { LoginState } from './data/Atom';

const USEABLE_PATH_WITH_NOT_TOKEN = ['/', '/glotai', '/pricing', '/login', '/admin'];

function App() {
  const navigate = useNavigate();

  function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`); //"--vh"라는 속성으로 정의해준다.
  }

  window.addEventListener('resize', () => setScreenSize());

  const [loginState] = useRecoilState(LoginState);
  const [cookies] = useCookies(['token']);
  useEffect(() => {
    // 로그아웃을 알릴 필요가 없는 페이지에서는 체크 X
    if (!!USEABLE_PATH_WITH_NOT_TOKEN.includes(window.location.pathname)) {
      return;
    }
    if (!loginState || localStorage.getItem('token')) {
      return;
    }

    alert('로그인이 만료되었습니다. 다시 로그인해주세요.');
    navigate('/login');

    // cookie가 삭제됐을 떄를 의존하여 로그인 상태를 체크
  }, [loginState, cookies.token]);
  return (
    <Routes>
      <Route path="/" element={<About />} />
      <Route path="/glotai" element={<GlotAi />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/login" element={<Login />} />
      <Route path="/member/idsearch" element={<Idsearch />} />
      <Route path="/member/changepw" element={<ChangePw />} />
      <Route path="/member/newpw" element={<NewPw />} />
      <Route path="/member/signup" element={<SignUp />} />
      <Route path="/writing" element={<Writing />} />
      <Route path="/writing/:id" element={<Writing />} />
      <Route path="/mypage" element={<Mypage />} />
      <Route path="/mypage/groupinfo" element={<GroupInfo />} />
      <Route path="/mypage/paid_info" element={<PaidInfo />} />
      <Route path="/mypage/free_info" element={<FreeInfo />} />
      <Route path="/payment/basic" element={<BasicPrice />} />
      <Route path="/payment/enterprise" element={<EnterprisePrice />} />
      <Route path="/payment" element={<Payment />} />
      <Route path="/admin" element={<AdminLogin />} />
      <Route path="/admin/user" element={<MemberList />} />
      <Route path="/admin/faq" element={<FAQ />} />
      <Route path="/admin/faq/enroll" element={<FAQEnroll />} />
      <Route path="/admin/faq/enroll/:id" element={<FAQEnroll />} />
      <Route path="/admin/plan" element={<Plan />} />
    </Routes>
  );
}

export default App;
