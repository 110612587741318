import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useMediaQuery } from 'react-responsive';

import { WritingContent, WritingTitle, UserType, LoginState } from '../../data/Atom';

import { useRecoilState } from 'recoil';

import WritingDownload from '../Modal/WritingDownload';

import WritingMoveApi from '../../services/WritingMove';
import { UsePostApi } from '../../services/http';

import close from '../../asset/close.png';
import more from '../../asset/more.png';
import plus from '../../asset/plus.png';

export default function SideBar(props) {
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });

  const modalRef = useRef();

  const navigate = useNavigate();
  const { id: writingId } = useParams(); // 작문 고유 id
  const [writingTitle, setWritingTitle] = useRecoilState(WritingTitle); // 작문 제목
  const [writingContent, setWritingContent] = useRecoilState(WritingContent); // 작문 내용
  const [userType, setUserType] = useRecoilState(UserType); // 사용자 타입
  const [loginState, setLoginState] = useRecoilState(LoginState); // 로그인 상태

  const [isOpenDownload, setIsOpenDownload] = useState(false); // 다운로드 모달창 제어
  const [clickCoordinates, setClickCoordinates] = useState({ x: 0, y: 0 }); // 클릭 좌표

  const writingList = props.writingList;

  // 모달 창 외부 클릭 시 모달 닫기
  useEffect(() => {
    const handleClickOutside = e => {
      if (isOpenDownload && !modalRef.current.contains(e.target)) setIsOpenDownload(false);
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpenDownload]);

  // 사이드바 닫기
  const onClickClose = () => {
    props.setIsSideBarOpen(false);
  };

  const onClickStart = () => {
    if (!loginState) {
      alert('유료 계정으로 로그인하신 후 이용해 주세요.');
      return;
    }

    if (loginState && userType === 'FREE') {
      alert('유료 계정으로 전환하신 후 이용해 주세요.');
      return;
    }

    if (writingId) {
      UsePostApi(
        '/writing',
        { writingBoardId: writingId, title: writingTitle, content: writingContent },
        { auth: localStorage.getItem('token') }
      )
        .then(response => {
          navigate('/writing');
          setWritingTitle('');
          setWritingContent('');
        })
        .catch(error => {
          console.log(error);
        });
    }
    if (isMobile) {
      props.setIsSideBarOpen(false);
    }
  };

  const handleClick = e => {
    const x = e.clientX;
    const y = e.clientY;
    setClickCoordinates({ x, y });
  };

  const onClickDown = writingId => {
    if (isMobile) {
      alert('작문 삭제, 다운로드 기능은 PC 환경에서 이용할 수 있습니다.');
      return;
    }
    navigate(`/writing/${writingId}`);
    setIsOpenDownload(true); // 모달 창을 여는 동작은 좌표 업데이트 이후에 실행
  };

  //작성일 기준 내림차순 정렬
  const sortedList = writingList.sort((a, b) => {
    const dateA = new Date(a.yearMonth);
    const dateB = new Date(b.yearMonth);

    return dateB - dateA;
  });

  // 작성일 기준으로 그룹화
  const groupedHistory = sortedList.reduce((result, item) => {
    const yearMonth = item.yearMonth;

    if (!result[yearMonth]) {
      result[yearMonth] = [];
    }

    result[yearMonth].push(item);
    return result;
  }, {});

  // 드래그 앤 드롭
  const onDragEnd = result => {
    if (!result.destination) {
      return; // 항목을 올바른 위치로 이동하지 않았을 때 종료
    }

    const sourceGroup = groupedHistory[result.source.droppableId]; // 이동할 작문 아이템이 속한 그룹
    const destinationGroup = groupedHistory[result.destination.droppableId]; // 이동한 위치의 그룹

    const sourceId = result.source.droppableId; // 이동할 작문 아이템이 속한 그룹의 ID
    const destinationId = result.destination.droppableId; // 이동한 위치의 그룹의 ID

    const sourceIndex = result.source.index; // 이동할 작문 아이템의 인덱스
    const destinationIndex = result.destination.index; // 이동한 위치의 인덱스

    if (sourceId === destinationId) {
      const sourceItem = groupedHistory[sourceId][sourceIndex];
      const destinationItem = groupedHistory[destinationId][destinationIndex];

      if (sourceItem.yearMonth === destinationItem.yearMonth) {
        WritingMoveApi(sourceItem.id, destinationItem.id)
          .then(response => {})
          .catch(error => {
            console.log(error);
          });
      }
    }

    // 같은 달 내에서만 이동 가능하도록 체크
    if (result.source.droppableId === result.destination.droppableId) {
      // 이동한 작문 아이템 가져오기
      const movedItem = sourceGroup.splice(result.source.index, 1)[0];

      // 대상 그룹으로 이동
      destinationGroup.splice(result.destination.index, 0, movedItem);

      // 작문 아이템을 다시 그룹화
      const newGroupedHistory = { ...groupedHistory };
      newGroupedHistory[result.source.droppableId] = sourceGroup;
      newGroupedHistory[result.destination.droppableId] = destinationGroup;

      // 상태 업데이트
      // 이곳에서 API 호출 또는 상태 업데이트를 수행할 수 있습니다.
    } else {
      // 다른 달로 이동 제한
      alert('같은 달 내에서만 이동 가능합니다.');
    }
    // 드래그 앤 드롭 완료
    setIsOpenDownload(false);
  };

  const category = yearMonth => {
    //올해이면 월만 표시
    if (dayjs().format('YYYY') === dayjs(yearMonth).format('YYYY')) {
      return dayjs(yearMonth).format('MM');
    }
    //올해가 아니면 년도와 월 표시
    else {
      return dayjs(yearMonth).format('YYYY-MM');
    }
  };

  return (
    <Container isSideBarOpen={props.isSideBarOpen} sidebarHeight={props.sidebarHeight}>
      <TopArea>
        {isMobile ? (
          <Title>작문 목록</Title>
        ) : (
          <Title>
            GLOT <Bold>Writing</Bold>
          </Title>
        )}
        <Button onClick={onClickClose}>
          <img
            src={close}
            alt="close"
            style={{
              width: '14px',
              height: '14px'
            }}
          />
        </Button>
      </TopArea>
      {/* 과금을 한 user만 새로운 작문 기능 사용 가능(저장도 마찬가지) + 새로운 작문을 시작한 형태인 경우에도 버튼이 존재하지 않음(writingId로 판단) */}
      {loginState && userType !== 'FREE' && !!writingId && (
        <ButtonArea>
          <StartButtonOuter>
            <StartButton onClick={onClickStart}>
              <Icon>
                <img
                  src={plus}
                  alt="plus"
                  style={{
                    width: '10px',
                    height: '10px'
                  }}
                />
              </Icon>
              <Text>새로운 작문 시작</Text>
            </StartButton>
          </StartButtonOuter>
        </ButtonArea>
      )}
      <WritingListArea>
        <DragDropContext onDragEnd={onDragEnd}>
          {Object.keys(groupedHistory).map(yearMonth => (
            <div key={yearMonth}>
              <MonthTitle>{category(yearMonth)}월</MonthTitle>
              <Droppable droppableId={yearMonth} type="WRITING">
                {(provided, snapshot) => (
                  <WritingListArea
                    ref={provided.innerRef}
                    isDraggingOver={snapshot.isDraggingOver}
                    onClick={handleClick}
                  >
                    {groupedHistory[yearMonth].map((item, index) => (
                      <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                        {(provided, snapshot) => (
                          <WritingOuter
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onClick={() => {
                              navigate(`/writing/${item.id}`);
                              if (isMobile) {
                                props.setIsSideBarOpen(false);
                              }
                            }}
                          >
                            <WritingItem>{item.title}</WritingItem>
                            <MoreButton onClick={() => onClickDown(item.id)} ref={modalRef}>
                              <img src={more} alt="more" />
                            </MoreButton>
                          </WritingOuter>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </WritingListArea>
                )}
              </Droppable>
            </div>
          ))}
        </DragDropContext>
      </WritingListArea>
      {isOpenDownload && (
        <WritingDownload
          setIsOpenDownload={setIsOpenDownload}
          setIsDeleteModalOpen={props.setIsDeleteModalOpen}
          clickCoordinates={clickCoordinates}
          writingListUpdate={props.writingListUpdate}
          setWritingListUpdate={props.setWritingListUpdate}
          modalRef={modalRef}
          onClickPrint={props.onClickPrint}
          onClickSaveAsJpg={props.onClickSaveAsJpg}
          onClickSaveAsTxt={props.onClickSaveAsTxt}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 214px;
  height: ${props => props.sidebarHeight}px;
  min-width: 214px;
  min-height: calc(100vh - 80px);
  padding: 8px;
  background: rgba(252, 252, 252, 1);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-right: 1px solid rgba(242, 243, 245, 1);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.04);
  z-index: 9;
  overflow-y: auto;
  @media (max-width: 768px) {
    width: calc(100% - 16px);
    height: calc(100% + 100px);
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const TopArea = styled.div`
  width: 90%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f2f3f5;
  padding-bottom: 5px;
  letter-spacing: -0.03em;
  @media (max-width: 768px) {
    width: 100%;
    height: 80px;
  }
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: -0.03em;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
  }
`;

const Bold = styled.span`
  font-weight: 700;
  margin-left: 5px;
`;

const Button = styled.button`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: -8px;
  background-color: rgba(252, 252, 252, 1);
  &:hover {
    background-color: #f2f3f5;
  }
`;

const ButtonArea = styled.div`
  width: 208px;
  height: 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
`;

const StartButtonOuter = styled.div`
  width: 204px;
  height: 40px;
  border-radius: 999px;
  background: linear-gradient(98.97deg, #54c8ed 23.83%, #dfc361 71.97%);
  color: #3c3e3f;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

const StartButton = styled.div`
  width: calc(100% - 2px);
  height: 38px;
  border-radius: 999px;
  background: #ffffff;
  color: #3c3e3f;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

const Icon = styled.div`
  width: 26px;
  height: 26px;
  position: relative;
  left: -30px;
  background-color: #f2f3f5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #97989a;
  @media (max-width: 768px) {
    left: -30px;
  }
`;

const Text = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
`;

const WritingListArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  @media (max-width: 768px) {
    margin-top: 20px;
  }
`;

const MonthTitle = styled.div`
  width: calc(100% - 8px);
  height: 30px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: rgba(153, 153, 153, 1);
`;

const WritingOuter = styled.div`
  width: calc(100% - 8px);
  height: 40px;
  border-radius: 8px;
  padding-left: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: rgba(60, 62, 63, 1);
  cursor: pointer;
  &:hover {
    background: rgba(242, 243, 245, 1);
  }
`;

const WritingItem = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 25px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(60, 62, 63, 1);
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const MoreButton = styled.div`
  display: flex;
  margin-right: 5px;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: rgba(60, 62, 63, 1);
  cursor: pointer;
`;
