import React from 'react';
import styled from 'styled-components';

import pdf from '../../asset/pdf.png';

import { PDFDownloadLink, Document, Page, Text, StyleSheet, Font } from '@react-pdf/renderer';
import pretendardVariable from '../../styles/fonts/PretendardVariable.ttf';

Font.register({
  family: 'Pretendard',
  src: pretendardVariable
});

const styles = StyleSheet.create({
  page: {
    padding: '48px',
    backgroundColor: '#ffffff'
  },
  title: {
    fontSize: '24px',
    fontWeight: 600,
    fontFamily: 'Pretendard',
    padding: '1px 2px',
    marginBottom: '20px'
  },
  content: {
    padding: '2px',
    fontSize: '13px',
    fontWeight: 400,
    fontFamily: 'Pretendard'
  }
});

const BaseDocument = ({ title, content, ...props }) => {
  return (
    <Document {...props}>
      <Page size="A4" orientation="portrait" style={styles.page}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.content}>{content}</Text>
      </Page>
    </Document>
  );
};

const SaveWritingAsPdfButton = ({ title, content, callback }) => (
  <PDFDownloadLink
    document={<BaseDocument title={title} content={content} />}
    fileName={`${title}.pdf`}
    style={{ textDecoration: 'none' }}
  >
    {({ url, loading }) => {
      const handleDownloadPdf = e => {
        e.preventDefault();

        if (loading) {
          return;
        }

        const a = document.createElement('a');
        a.href = url;
        a.style.display = 'none';
        a.download = `${title}.pdf`;

        document.body.appendChild(a);
        a.click();
        a.remove();

        callback();
      };

      return (
        <SelectContent onClick={handleDownloadPdf}>
          <SelectIcon>
            <img
              style={{
                width: '100%',
                height: '100%'
              }}
              src={pdf}
              alt="pdf"
            />
          </SelectIcon>
          <SelectTitle>PDF 다운로드</SelectTitle>
        </SelectContent>
      );
    }}
  </PDFDownloadLink>
);

const SelectContent = styled.div`
  cursor: pointer;
  width: calc(100% - 16px);
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px;
  &:hover {
    background: rgba(242, 243, 245, 1);
    border-radius: 6px;
  }
`;
const SelectIcon = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SelectTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #5b5c5e;
`;

export default SaveWritingAsPdfButton;
