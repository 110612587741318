import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import MainTopBar from '../../components/Main/MainTopBar';
import MainFAQ from '../../components/Main/MainFAQ';
import MainFooter from '../../components/Main/MainFooter';
import MainCarousal from '../../components/Main/MainCarousal';
import MainMenu from '../../components/Main/MainMenu';
import Slide from '../../components/Main/Carousal';

import mainImg from '../../asset/main001_1.png';
import mainImg2 from '../../asset/main001_2.png';
import AppStore from '../../asset/AppStore.png';
import PlayStore from '../../asset/PlayStore.png';
import slide1 from '../../asset/main001_3.png';
import slide2 from '../../asset/main001_4.png';
import slide3 from '../../asset/main001_5.png';
import frame2 from '../../asset/GlotFrame.png';
import card1 from '../../asset/main001_card_1.png';
import card2 from '../../asset/main001_card_2.png';
import card3 from '../../asset/main001_card_3.png';
import banner from '../../asset/main001_banner_1.png';
import banner1 from '../../asset/main001_banner_2.png';
import vector from '../../asset/blackVector.png';
import whiteVector from '../../asset/whiteVector.png';
import mobile001 from '../../asset/mobile001_1.png';
import mobile002 from '../../asset/mobile001_2.png';
import mobile003 from '../../asset/mobile001_3.png';

export default function About() {
  const navigate = useNavigate();

  const isTablet = useMediaQuery({
    query: '(max-width: 960px)'
  });

  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });

  const onClickStart = () => {
    window.open(window.origin + '/writing', '_blank');
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onClickStore = () => {
    alert('준비중입니다.');
  };

  return (
    <MainContainer>
      <BackgroundImg>
        <MainTopBar setIsMenuOpen={setIsMenuOpen} />
        <MainContent>
          <MainText>
            <Title>
              Save your time on Writing.{'\n'}
              And then Invest your time{'\n'}
              in realizing ideal.
            </Title>
            <Description>
              작문에 투자하는 시간을 절약하세요.{'\n'}
              그리고 이상 실현에 여러분의 시간을 투자하세요!
            </Description>
            <ButtonArea>
              <DownButton onClick={onClickStore}>
                <Icon src={AppStore} alt="appStore" />
                App Store
              </DownButton>
              <DownButton onClick={onClickStore}>
                <Icon src={PlayStore} alt="playStore" />
                Play Store
              </DownButton>
            </ButtonArea>
          </MainText>
          <MainButton
            style={{
              display: isTablet ? 'block' : 'none',
              marginTop: '50px'
            }}
            onClick={onClickStart}
          >
            GLOT Writing 시작하기
          </MainButton>
          <MainImage src={frame2} />
        </MainContent>
      </BackgroundImg>
      <MainDescription>
        <MainDescriptionTitle>
          {isMobile ? (
            <div
              className="line"
              style={{
                fontWeight: '600',
                wordBreak: 'keep-all'
              }}
            >
              <Blue>첫 번째 이야기</Blue>&nbsp;GLOT 로직(logic)을 학습시켜 개발된 GLOT 인공 지능(AI)
            </div>
          ) : (
            <div className="line">
              <Blue>첫 번째 이야기</Blue>&nbsp;GLOT 로직(logic)을 학습시켜 개발된 GLOT 인공 지능(AI)
            </div>
          )}
        </MainDescriptionTitle>
        <MainDescriptionContent>GLOT 로직(logic)과 GLOT 인공 지능(AI)</MainDescriptionContent>
        <MainDescriptionContainer src={isMobile ? mobile001 : slide1}></MainDescriptionContainer>
        <MainDescriptionContainer src={isMobile ? mobile002 : slide2}></MainDescriptionContainer>
        <MainDescriptionContainer src={isMobile ? mobile003 : slide3}></MainDescriptionContainer>
      </MainDescription>
      <MainImgArea>
        <MainImgTitle>
          <span style={{ color: '#8a9cfd' }}>세 번째 이야기{'\n'}</span>
          작문에 최적화된 GLOT 인공 지능(AI), GLOT Writing
        </MainImgTitle>
        {isMobile ? (
          <Slide page="1" />
        ) : (
          <MainImgContent>
            <MainImgContentOuter>
              <CardImage src={card1} />
            </MainImgContentOuter>
            <MainImgContentOuter>
              <CardImage src={card2} />
            </MainImgContentOuter>
            <MainImgContentOuter>
              <CardImage src={card3} />
            </MainImgContentOuter>
          </MainImgContent>
        )}
        <MainImgButtonArea>
          <MainImgButton
            onClick={() => {
              navigate('/glotai');
              window.scrollTo(0, 0);
            }}
          >
            GLOT Writing 더 알아보기
            <Arrow src={whiteVector} alt="vector" />
          </MainImgButton>
        </MainImgButtonArea>
      </MainImgArea>
      <MainCarousal />
      <MainBannerArea
        style={{
          backgroundImage: `url(${banner1})`
        }}
      >
        {isMobile ? (
          <BannerText>이제 GLOT Writing과 함께{'\n'}'작문의 신'이 되세요.</BannerText>
        ) : (
          <BannerText>이제 GLOT Writing과 함께 '작문의 신'이 되세요.</BannerText>
        )}
        <BannerButtonArea>
          <BannerButton onClick={onClickStart}>
            GLOT Writing 무료 체험
            <Arrow src={vector} alt="vector" />
          </BannerButton>
        </BannerButtonArea>
      </MainBannerArea>
      <MainFAQ />
      <MainBannerArea
        style={{
          color: 'rgba(255, 255, 255, 1)',
          backgroundImage: `url(${banner})`,
          backgroundSize: 'cover'
        }}
      >
        {isMobile ? (
          <BottomBannerText>
            레토리케는 'GLOT 로직(logic)'을{'\n'} '인공 지능(AI)'으로 만들어 전 세계 모든 사람들에게 보급하려고 합니다.
          </BottomBannerText>
        ) : (
          <BottomBannerText>
            레토리케는 'GLOT 로직(logic)'을 '인공 지능(AI)'으로 만들어 전 세계 모든 사람들에게 보급하려고 합니다.
          </BottomBannerText>
        )}
      </MainBannerArea>
      <MainFooter />
      {isMenuOpen && <MainMenu setIsMenuOpen={setIsMenuOpen} />}
    </MainContainer>
  );
}

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const BackgroundImg = styled.div`
  background-color: #f2f3f5;
  background-image: url(${mainImg}); // mainImg를 배경 이미지로 설정
  background-size: cover; // 배경 이미지를 꽉 채우도록 설정
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const MainContent = styled.div`
  width: 100%;
  height: 797px;
  display: flex;
  justify-content: center;
  text-align: center;
  @media (max-width: 1440px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }
`;

const MainText = styled.div`
  height: 366px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-top: 197px;
  @media (max-width: 768px) {
    margin-top: 64px;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;

const Title = styled.div`
  color: rgba(255, 255, 255, 1);
  font-size: 46px;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: -0.03em;
  text-align: left;
  white-space: pre-line;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.03em;
    text-align: center;
  }
`;

const Description = styled.div`
  width: 100%;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.03em;
  text-align: left;
  white-space: pre-line;
  margin-top: 16px;
  color: rgba(77, 100, 122, 1);
  @media (max-width: 768px) {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.03em;
    text-align: center;
    margin-top: 16px;
  }
`;

const ButtonArea = styled.div`
  width: 264px;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 28px;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
  }
`;

const DownButton = styled.div`
  width: 128px;
  height: 48px;
  border: none;
  border-radius: 10px;
  background-color: rgba(74, 93, 113, 0.4);
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: -0.03em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 128px;
    height: 48px;
    font-size: 14px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: -0.03em;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
  }
`;

const Icon = styled.img`
  width: 20px;
  height: 23px;
  margin-right: 8px;
`;

const MainButton = styled.div`
  padding: 13px;
  border: none;
  border-radius: 10px;
  background-color: rgba(50, 144, 255, 1);
  color: #ffffff;
  font-size: 17px;
  font-weight: 700;
  cursor: pointer;
  letter-spacing: -0.3px;
`;

const MainImage = styled.img`
  width: 699.6px;
  height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 110px;
  margin-left: 10px;
  @media (max-width: 1440px) {
    width: 70%;
    height: auto;
    margin-top: 64px;
    margin-left: 0px;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin-top: 48px;
    margin-left: 0px;
  }
`;

const MainDescription = styled.div`
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: 78px;
  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
`;

const MainDescriptionTitle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 48px;
  font-weight: 600;
  margin-top: 112px;
  letter-spacing: -0.05em;
  @media (max-width: 1440px) {
    font-size: 34px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.03em;
    text-align: center;
    margin-top: 84px;
  }
  @media (max-width: 768px) {
    font-size: 22px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.03em;
    text-align: center;
    margin-top: 48px;
    white-space: pre-line;
  }
  @media (max-width: 481px) {
    width: 90%;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.03em;
    text-align: center;
    margin-top: 48px;
    margin-left: 5%;
  }
`;

const MainDescriptionContent = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 32px;
  font-weight: 700;
  margin-top: 16px;
  margin-bottom: 56px;
  color: rgba(210, 220, 240, 1);
  letter-spacing: -0.05em;
  @media (max-width: 1440px) {
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.03em;
    text-align: center;
  }
  @media (max-width: 768px) {
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.03em;
    text-align: center;
    margin-top: 16px;
    margin-bottom: 32px;
  }
  @media (max-width: 481px) {
    font-size: 14px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.03em;
    text-align: center;
    height: 13px;
    margin-top: 0px;
  }
`;

const MainDescriptionContainer = styled.img`
  width: 909px;
  height: auto;
  max-width: 1150px;
  border-radius: 32px;
  margin: 0 auto; /* 좌우 중앙 정렬을 위해 추가 */
  margin-bottom: 20px;
  @media (max-width: 1440px) {
    width: 100%;
    height: auto;
    max-width: 909px;
    border-radius: 32px;
    margin: 0 auto; /* 좌우 중앙 정렬을 위해 추가 */
    margin-bottom: 20px;
  }
  @media (max-width: 768px) {
    width: 90%;
    height: auto;
    max-width: 909px;
    border-radius: 32px;
    margin: 0 auto; /* 좌우 중앙 정렬을 위해 추가 */
    margin-bottom: 20px;
  }
`;

const MainImgArea = styled.div`
  width: 100%;
  background-image: url(${mainImg2});
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const MainImgTitle = styled.div`
  width: 100%;
  height: 100%;
  font-size: 48px;
  font-weight: 500;
  line-height: 62px;
  letter-spacing: -0.03em;
  text-align: center;
  white-space: pre-line;
  color: rgba(255, 255, 255, 1);
  margin: 0 auto; /* 좌우 중앙 정렬을 위해 추가 */
  margin-top: 104px;
  @media (max-width: 1440px) {
    font-size: 34px;
    font-weight: 600;
    line-height: 40px;
    margin-top: 84px;
  }
  @media (max-width: 768px) {
    font-size: 22px;
    font-weight: 600;
    line-height: 32px;
    margin-top: 48px;
  }
  @media (max-width: 481px) {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
  }
`;

const MainImgContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0 auto; /* 좌우 중앙 정렬을 위해 추가 */
  margin-top: 74px;
`;

const MainImgContentOuter = styled.div`
  width: 414px;
  height: 451px;
  border-radius: 20px;
  @media (max-width: 1150px) {
    margin-top: 16px;
  }
  @media (max-width: 768px) {
    width: 80%;
    height: auto;
    border-radius: 20px;
    margin-top: 16px;
  }
`;

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 20px;
`;

const MainImgButtonArea = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 57px;
  margin-bottom: 76px;
`;

const MainImgButton = styled.div`
  padding: 0 10px;
  height: 61px;
  color: rgba(255, 255, 255, 1);
  font-size: 17px;
  font-weight: 700;
  border: 1.3px solid #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: rgba(243, 233, 255, 0.03);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  @media (max-width: 1440px) {
    height: 42px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.03em;
    text-align: center;
  }
`;

const Arrow = styled.img`
  width: 18px;
  height: 15px;
  margin-left: 10px;
  @media (max-width: 781px) {
    width: 15px;
    height: 12px;
    margin-left: 5px;
  }
  @media (max-width: 591px) {
    width: 10px;
    height: 10px;
    margin-left: 3px;
  }
`;

const MainBannerArea = styled.div`
  height: 100%;
  background: linear-gradient(180deg, #010101 0%, #010101 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

const BannerButtonArea = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 42px;
  @media (max-width: 781px) {
    margin-top: 16px;
    margin-bottom: 32px;
  }
  @media (max-width: 591px) {
    margin-top: 16px;
    margin-bottom: 32px;
  }
`;

const BannerButton = styled.div`
  padding: 0 10px;
  height: 57px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 1);
  font-size: 17px;
  font-weight: 700;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: 1440px) {
    width: 156px;
    height: 42px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: -0.03em;
    text-align: center;
  }
`;

const BannerText = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  margin-top: 42px;
  background: linear-gradient(92.34deg, #d0e6ff 21.01%, #8effb5 75.27%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: 1440px) {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.03em;
    text-align: center;
    margin-top: 42px;
  }
  @media (max-width: 768px) {
    font-size: 22px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.03em;
    text-align: center;
    margin-top: 42px;
    white-space: pre-line;
  }
`;

const BottomBannerText = styled.div`
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.03em;
  text-align: center;
  color: 'rgba(255, 255, 255, 1)';
  margin-top: 52px;
  margin-bottom: 52px;
  word-break: keep-all;
  @media (max-width: 1440px) {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.03em;
    text-align: center;
    margin-top: 52px;
    margin-bottom: 52px;
  }
  @media (max-width: 768px) {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.03em;
    text-align: center;
    margin-top: 52px;
    margin-bottom: 52px;
    white-space: pre-line;
  }
`;

const Blue = styled.span`
  color: #4c68fa;
`;
