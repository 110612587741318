import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import { UseGetApi } from '../../services/http';

import icon from '../../asset/FAQ.png';
import open from '../../asset/FAQopen.png';
import close from '../../asset/FAQclose.png';

export default function FAQ() {
    const [expandedItems, setExpandedItems] = useState({});
    const [faqList, setFaqList] = useState([]);

    const fetchData = async () => {
        const response = await UseGetApi('/faq', { page: 0, size: 10 });
        setFaqList(response.data.faqList);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const toggleItem = (itemId) => {
      setExpandedItems((prevItems) => ({
        ...prevItems,
        [itemId]: !prevItems[itemId],
      }));
    };

    return(
        <MainFAQ>
            <MainDescriptionTitle>
                FAQ
            </MainDescriptionTitle>
            <MainDescriptionContent
                style={{
                    height: '100%',
                    margin: '0 auto',
                    marginBottom: '64px',
                    flexDirection: 'column',
                }}
            >
              {faqList.map((item, index) => (
                <FAQComponent
                    key={index}
                    style={{ height: expandedItems[index] ? '100%' : '72px' }}
                >
                    <FAQTitle style={{borderBottom : expandedItems[index] ? '1px solid rgba(234, 235, 237, 1)' : 'none'}}>
                        <Container>
                            <FAQIcon src={icon} alt='icon' />
                            {item.title}
                        </Container>
                        <FAQButton
                            src={expandedItems[index] ? close : open}
                            alt='open'
                            onClick={() => toggleItem(index)}
                        ></FAQButton>
                    </FAQTitle>
                    {expandedItems[index] && 
                        item.content.map((text, index) => (
                            <FAQContent key={index}>
                                <Bold>
                                    {text.subtitle}
                                </Bold>
                                <br />
                                {text.subContent}
                            </FAQContent>
                        ))
                    }
                </FAQComponent>
                ))}
            </MainDescriptionContent>
        </MainFAQ>
    )
}

const MainFAQ = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;

const MainDescriptionTitle = styled.div`
    width: 100%;
    height: 53px;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 53px;
    font-weight: 600;
    margin-top: 56px;
    margin-bottom: 21px;
    @media (max-width: 768px) {
        font-size: 32px;
        margin-top: 32px;
        margin-bottom: 8px;
    }
`;

const MainDescriptionContent = styled.div`
    height: 51px;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    margin-top: 8px;
    margin-bottom: 64px;
    color: rgba(210, 220, 240, 1);
`;

const FAQComponent = styled.div`
    width: 757px;
    height: 72px;
    border-radius: 13px;
    background-color: rgba(248, 249, 251, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    transition: height 0.3s;
    @media (max-width: 768px) {
        width: 90%;
        margin-left: 5%;
    }
`;

const FAQTitle = styled.div`
    width: calc(100% - 32px);
    height: 72px;
    font-size: 17px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: -0.05em;
    text-align: left;
    color: rgba(111, 112, 113, 1);
    padding-left: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    border-bottom: 1px solid rgba(234, 235, 237, 1);
    @media (max-width: 768px) {
        width: 100%;
        font-size: 12px;
    }
`;

const Bold = styled.span`
    font-weight: 700;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    margin-left: 12px;
    @media (max-width: 768px) {
        margin-left: 0px;
    }
`;

const FAQContent = styled.div`
    width: 657px;
    // border-top: 1px solid rgba(234, 235, 237, 1);
    padding: 16px 50px;
    white-space: pre-line;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.8;
    letter-spacing: -0.03em;
    text-align: left;
    color: rgba(111, 112, 113, 1);
    margin-bottom: 16px;
    @media (max-width: 768px) {
        width: 80%;
        font-size: 11px;
        padding: 12px 30px;
    }
`;

const FAQIcon = styled.img`
    width: 32px;
    height: 32px;
    margin-right: 12px;
    @media (max-width: 768px) {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
`;

const FAQButton = styled.img`
    margin-right: 28px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    @media (max-width: 768px) {
        margin-left: 4px;
        width: 18px;
        height: 18px;
    }
`;