import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Calendar from 'react-calendar'
import moment from 'moment'
import 'react-calendar/dist/Calendar.css'

const CustomCalendar = props => {
  const formatDate = date => {
    // moment 라이브러리를 사용하여 날짜를 원하는 형식으로 포맷팅
    return moment(date).format('YYYY.MM.DD')
  }

  return (
    <CalendarContainer
      style={{
        top: props.clickCoordinates.y,
        left: props.clickCoordinates.x
      }}
    >
      <CalendarWrapper isOpen={props.isOpen}>
        <Calendar
          onChange={props.setDate}
          value={props.date}
          onClickDay={() => props.setIsOpen(false)}
          formatDay={(locale, date) => moment(date).format('DD')}
        ></Calendar>
      </CalendarWrapper>
    </CalendarContainer>
  )
}

export default CustomCalendar

const CalendarContainer = styled.div`
  position: absolute;
`

const CalendarWrapper = styled.div`
  z-index: 11;
  position: absolute;
  top: 100%;
  left: 0;
`
