import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const LoginState = atom({
  key: 'LoginState',
  default: false,
  effects_UNSTABLE: [persistAtom]
});

export const AdminState = atom({
  key: 'AdminState',
  default: false,
  effects_UNSTABLE: [persistAtom]
});

export const Id = atom({
  key: 'Id',
  default: ''
});

export const Pw = atom({
  key: 'Pw',
  default: ''
});

export const Code = atom({
  key: 'Code',
  default: ''
});

export const WritingContent = atom({
  key: 'WritingContent',
  default: ''
});

export const WritingTitle = atom({
  key: 'WritingTitle',
  default: ''
});

export const WritingList = atom({
  key: 'WritingList',
  default: []
});

export const SentenceList = atom({
  key: 'SentenceList',
  default: []
  //effects_UNSTABLE: [persistAtom],
});

export const Sentence = atom({
  key: 'Sentence',
  default: ''
});

export const SentenceType = atom({
  key: 'SentenceType',
  default: ''
});

export const UserType = atom({
  key: 'UserType',
  default: 'FREE',
  effects_UNSTABLE: [persistAtom]
});
