import { WRITING_MESSAGE } from '../constants/CustomMessage'
import { customNotify } from './customNotify'
import { sliceLastSentence } from './sliceLastSentence'

export const isSentenceValid = sentence => {
  const sentenceEndingPattern = /[.!?]\s*$/
  //info : 문장의 종결 표현 . ! ? 중 하나로 끝나는지 확인
  if (!sentenceEndingPattern.test(sentence)) {
    customNotify(WRITING_MESSAGE.WRONG_SENTENCE_ENDING, 'warning')
    return false
  }
  //info : "/" 기준 앞의 문장이 "...", ".!", ".?". ".!?" 등 ". ! ?" 로 끝나는지 확인
  if (sliceLastSentence(sentence) === '') {
    customNotify(WRITING_MESSAGE.WRONG_SENTENCE_ENDING, 'warning')
    return false
  } else return true
}
