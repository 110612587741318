import React from "react";
import styled from "styled-components";
import Pagination from "react-js-pagination";
 
const Paging = ({ page, itemsPerPage, count, setPage }) => {
  
    return (
      <Container>
        <Pagination
          activePage={page} // 현재 페이지
          itemsCountPerPage={itemsPerPage} // 한 페이지에 보여줄 아이템의 개수
          totalItemsCount={count} // 전체 회원 수
          pageRangeDisplayed={5} // paginator에 보여줄 페이지의 개수
          prevPageText={"<"}
          nextPageText={">"}
          onChange={setPage}
        />
      </Container>
    );
  };
 
export default Paging;

const Container = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .pagination {
        display: flex;
        justify-content: center;
        margin-top: 15px;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    ul.pagination li {
        display: inline-block;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
    }

    ul.pagination li:first-child {
        border-radius: 5px 0 0 5px;
    }

    ul.pagination li:last-child {
        border-radius: 0 5px 5px 0;
    }

    ul.pagination li a {
        text-decoration: none;
        color: #a8a8a8;
        font-size: 1rem;
        font-weight: bold;
    }

    ul.pagination li.active a {
        color: black;
    }

    ul.pagination li.active {

    }

    ul.pagination li a:hover,
    ul.pagination li a.active {
        color: blue;
    }

    .page-selection {
        width: 48px;
        height: 30px;
        color: #337ab7;
    }
`;

