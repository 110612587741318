import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import warning from '../../asset/modal_warning.png';

function NoResultModal({ setIsModal }) {
  const navigate = useNavigate();

  const closeInfoModal = () => {
    setIsModal(false);
    navigate('/member/changepw');
  };

  return (
    <ModalContainer>
      <Title>
        <img
          src={warning}
          alt="warning"
          style={{
            width: '24px',
            height: '24px',
            marginRight: '5px'
          }}
        />
        비밀번호 변경 결과
      </Title>
      <Description>
        입력하신 정보와는 일치하는 사용자가 없습니다.{'\n'}
        다음과 같은 이유로 오류가 표시될 수 있습니다.
      </Description>
      <ButtonArea>
        <Button onClick={closeInfoModal}>다시 찾기</Button>
        <Button
          style={{
            backgroundColor: '#eaebed',
            color: '#97989a'
          }}
        >
          취소
        </Button>
      </ButtonArea>
    </ModalContainer>
  );
}

const ModalContainer = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 418px;
  height: 270px;
  background-color: #fff;
  border-radius: 8px;
  z-index: 999;
  padding: 30px;
  @media (max-width: 768px) {
    width: 302px;
    height: 240px;
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;

const Description = styled.div`
  font-size: 16px;
  font-weight: 500;
  colr: #5b5c5e;
  margin-top: 20px;
  white-space: pre-line;
  line-height: 1.5;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ButtonArea = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 50px;
  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

const Button = styled.div`
  width: 200px;
  height: 50px;
  border: none;
  background-color: #3290ff;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 50px;
  @media (max-width: 768px) {
    font-size: 16px;
    width: 145px;
  }
`;

export default NoResultModal;
