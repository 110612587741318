import React, { useState } from 'react';
import styled from 'styled-components';
import CheckBox from '../CheckBox';
import { setTodayNotUsingWritingGuide } from '../../util/todayNotUsingWritingGuide';

const WritingGuideModal = ({ onClose }) => {
  const [checked, setChecked] = useState(false);

  const handleClose = () => {
    if (checked) {
      setTodayNotUsingWritingGuide();
    }
    onClose();
  };

  return (
    <ModalWrapper>
      <ModalTopBar>
        <Icon onClick={handleClose}>
          <svg width="16px" height="16px" viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 21.32L21 3.32001"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3 3.32001L21 21.32"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </Icon>
      </ModalTopBar>
      <ModalContent>
        <SectionTitle>
          <span>하나.</span> 사용자가 입력하는 첫 문장 예시: (첫 문장 작성 후 “/”를 입력하세요.)
        </SectionTitle>
        <Description>1. 유전 공학은 유전자를 연구하는 학문이다./ (정의)</Description>
        <Description>2. 새해는 해가 바뀌는 것이다./ (개념)</Description>
        <Description>3. 이것은 스마트폰이다./ (지정)</Description>
        <Description>4. 요즘 대세는 인공 지능이다./ (시사)</Description>

        <Separator />

        <SectionTitle>
          <span>둘.</span> GLOT Writng에는 특허를 획득한 GLOT(General Logic Of Text)
          <br />
          로직(logic)이 적용됩니다!
        </SectionTitle>

        <Description className="example">
          <span>
            <span>세희는</span>
            <br />
            <span>A</span>
          </span>
          &nbsp;
          <span>
            <span>유전 공학자이다.</span>
            <br />
            <span>B</span>
          </span>
          &emsp;[사용자 입력] + ‘/’를 누름
        </Description>
        <Description>
          <span className="not-decoration">
            <span>1단계</span>
            <br />
            <span>↓</span>
          </span>
          &nbsp;- [발전형] 그런데&nbsp;
          <span>
            <span>유전 공학자는</span>
            <br />
            <span>B</span>
          </span>
          &nbsp;
          <span>
            <span>의약계에 중요한 영향을 미친다.</span>
            <br />
            <span>C</span>
          </span>
        </Description>
        <Description>
          <span className="not-decoration">
            <span>2단계</span>
            <br />
            <span>↓</span>
          </span>
          &nbsp;- [반대형] 그러나&nbsp;
          <span>
            <span>유전 공학자는</span>
            <br />
            <span>B</span>
          </span>
          &nbsp;
          <span>
            <span>윤리적 문제를 야기하기도 한다.</span>
            <br />
            <span>-C</span>
          </span>
        </Description>
        <Description>
          3단계 - [결론형] 따라서&nbsp;
          <span>
            <span>[세희는]</span>
            <br />
            <span>(A)</span>
          </span>
          &nbsp;
          <span>
            <span>윤리적 문제를 야기하지 않도록 주의해야 한다.</span>
            <br />
            <span>-C!</span>
          </span>
        </Description>
      </ModalContent>
      <ModalFooter>
        <CheckArea>
          <CheckBox checked={checked} onChange={setChecked} />
          <span>오늘 하루는 안 볼게요.</span>
        </CheckArea>
        <Icon onClick={handleClose}>
          <svg width="16px" height="16px" viewBox="-0.5 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3 21.32L21 3.32001"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3 3.32001L21 21.32"
              stroke="#000000"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <CloseText>창 닫기</CloseText>
        </Icon>
      </ModalFooter>
    </ModalWrapper>
  );
};

const ModalFooter = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
`;
const CheckArea = styled.div`
  margin-right: 48px;

  & > span:last-of-type {
    font-size: 14px;
    font-weight: 600;
  }
`;
const Description = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
  @media (max-width: 768px) {
    font-size: 12px;
  }

  &.example {
    margin-left: 150px;
  }

  & > span {
    text-align: center;

    &:not(.not-decoration) {
      & > span {
        text-decoration: underline;
        &:last-of-type {
          text-decoration: none;
          color: rgba(50, 144, 255, 1);
        }
      }
    }
  }
`;

const Separator = styled.hr`
  width: 100%;
  border: 2px solid #eaebed;
  margin: 32px 0;
`;
const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
  color: rgba(50, 144, 255, 1);
  @media (max-width: 768px) {
    font-size: 15px;
    font-weight: 600;
  }

  & > span {
    color: #ff0000;
  }
`;
const CloseText = styled.p`
  margin: 0 0 0 8px;
  font-size: 14px;
  font-weight: 600;
`;
const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const ModalTopBar = styled.div`
  width: calc(100% - 16px);
  padding: 8px;
  margin-bottom: 12px;
  display: flex;
  justify-content: flex-end;
`;
const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const ModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translate(-10%, -50%);
  width: 490px;
  background-color: #ffffff;
  box-shadow: 0px 2px 14px 0px rgba(70, 71, 57, 0.12);
  border: 1px solid #eaebed;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 999;
  @media (max-width: 768px) {
    width: calc(100% - 16px);
    max-width: 480px;
    z-index: 8;
    border-top: 1px solid #eaebed;
    border-radius: 0px;
    box-shadow: none;
    background-color: #f8f9fb;
  }
`;

export default WritingGuideModal;
