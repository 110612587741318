import React from "react";
import {useState, useEffect} from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

import Sidebar from "./Components/Sidebar";

import { UseGetApi, UsePostApi, UseDeleteApi, UsePatchApi } from "../../services/http";

export default function Enroll() {
    const navigate = useNavigate();
    const { id } = useParams();

    const [type, setType] = useState("MAIN"); 
    const [title, setTitle] = useState("");
    const [contents, setContents] = useState([{subtitle: '', subContent: ''}]);

    useEffect(() => {
        if (id) {
            const fetchData = async () => {
                const response = await UseGetApi(`/faq/${id}`, { auth: localStorage.getItem('admin_Token')});
                setType(response.data.type);
                setTitle(response.data.title);
                setContents(response.data.content);
            }
            fetchData();
        }
    }, []);

    const onClickEnroll = async () => {
        if (id) {
            try {
                const response = await UsePatchApi(`/admin/faq/${id}`, { type: type, title: title, content: contents }, { auth: localStorage.getItem('admin_Token') });
                alert('수정되었습니다.');
                navigate('/admin/faq');
            } catch (error) {
                alert(error.message)
            }
        }
        else {
            try {
                const response = await UsePostApi('/admin/faq', { type: type, title: title, content: contents }, { auth: localStorage.getItem('admin_Token') });
                alert('등록되었습니다.');
                navigate('/admin/faq');
            } catch (error) {
                alert(error.message)
            }
        }
    }

    const onClickAdd = () => {
        setContents((prevContents) => [...prevContents, {subTitle: '', subContent: ''}]);
    }

    const onClickDelete = () => {
        if (contents.length > 1) {
            setContents((prevContents) => prevContents.slice(0, -1));
        }
    }

    return (
        <div>
            <Container>
                <Sidebar />
                <InnerContainer>
                    <TitleArea>
                        <Button
                            style={{
                                border: 'none',
                                width: '133px',
                            }}
                            onClick={() => {navigate('/admin/faq')}}
                        >
                            ← 목록으로 돌아가기
                        </Button>
                        <Button
                            style={{
                                marginTop: '30px',
                            }}
                            onClick={onClickEnroll}
                        >
                            게시하기
                        </Button>
                    </TitleArea>
                    <Content>
                        <Line>
                            <Text>
                                질문
                            </Text>
                            <Input
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            >
                            </Input>
                        </Line>
                        <Line>
                            <Text>
                                분류/구분
                            </Text>
                            <SelectBox
                                onChange={(e) => setType(e.target.value)}
                            >
                                <option value={'MAIN'}>메인페이지</option>
                                <option value={'PRICING'}>Pricing</option>
                            </SelectBox>
                        </Line>
                        <Line
                            style={{
                                alignItems: 'flex-start',
                            }}
                        >
                            <Text
                                style={{
                                    marginTop: '10px',
                                    width: '110px',
                                }}
                            >
                                내용
                            </Text>
                            <InputArea>
                                {contents.map((content, index) => (
                                    <WritingContent key={index}>
                                        <InputLine>
                                            <Text>
                                                소제목
                                            </Text>
                                            <Input
                                                style={{
                                                    width: '100%',
                                                }}
                                                value={content.subtitle}
                                                onChange={(e) => {
                                                    const updatedContents = [...contents];
                                                    updatedContents[index].subtitle = e.target.value;
                                                    setContents(updatedContents);
                                                }}
                                                placeholder="소제목을 입력하세요."
                                            />
                                        </InputLine>
                                        <InputLine
                                            style={{
                                                alignItems: 'flex-start',
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    marginTop: '10px',
                                                }}
                                            >
                                                답변 내용
                                            </Text>
                                            <Textarea
                                                value={content.subContent}
                                                onChange={(e) => {
                                                    const updatedContents = [...contents];
                                                    updatedContents[index].subContent = e.target.value;
                                                    setContents(updatedContents);
                                                }}
                                                placeholder="답변 내용을 입력하세요."
                                            />
                                        </InputLine>
                                    </WritingContent>
                                ))}
                            </InputArea>
                            <ButtonArea>
                                <Icon onClick={onClickAdd}>
                                    +
                                </Icon>
                                <Icon  onClick={onClickDelete}>
                                    -
                                </Icon>
                            </ButtonArea>
                        </Line>
                    </Content>
                </InnerContainer>
            </Container>
        </div>
    );
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 70px);
    display: flex;
    flex-direction: row;
    background-color: #fff;
`;

const InnerContainer = styled.div`
    width: calc(100% - 270px);
    height: 100%;
    min-height: 100vh;
    background-color: #fff;
`;

const TitleArea = styled.div`
    width: 95%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 24px 0px;
    border-bottom: 1px solid #e5e5e5;
`;

const Button = styled.button`
    width: 70px;
    height: 30px;
    background-color: #fff;
    border: 1px solid #e5e5e5;
    padding: 0px 10px;
    margin-left: 10px;
    cursor: pointer;
`;

const Content = styled.div`
    width: 95%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 24px 0px;
`;

const Line = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
`;

const Text = styled.div`
    width: 100px;
    height: 100%;
    padding-left: 10px;
    font-size: 13px;
    font-weight: bold;
`;

const Input = styled.input`
    width: calc(100% - 120px);
    height: 31px;
    border: 1px solid #e5e5e5;
    padding: 0px 10px;
`;

const SelectBox = styled.select`
    width: 100px;
    height: 31px;
    border: 1px solid #e5e5e5;
    padding: 0px 10px;
    margin-right: 10px;
`;

const InputArea = styled.div`
    width: calc(100% - 120px);
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-bottom: 0px;
    border: 1px solid #e5e5e5;
    background-color: #e9e9e9;
`;

const WritingContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
`;

const InputLine = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
`;

const Textarea = styled.textarea`
    width: calc(100% - 20px);
    height: 100%;
    min-height: 100px;
    padding: 10px;
    resize: none;
    border: 1px solid #e5e5e5;
`;

const ButtonArea = styled.div`
    height: 100%;
    display: flex;
    flex-direction: row;
    margin-left: 10px;
`;

const Icon = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #e5e5e5;
    margin-right: 10px;
`;