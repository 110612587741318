import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { UseGetApi } from '../../services/http';

import TopBar from '../../components/Main/TopBar';
import MainFooter from '../../components/Main/MainFooter';
import Menu from '../../components/Main/MainMenu';

import banner from '../../asset/main003_1.png';
import vector from '../../asset/blackVector.png';
import mobile from '../../asset/mobile003_1.png';

export default function Pricing() {
  const navigate = useNavigate();

  const isTablet = useMediaQuery({
    query: '(max-width: 768px)'
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [plan, setPlan] = useState([]);

  const fetchData = async () => {
    const response = await UseGetApi('/plan');
    setPlan(response.data.splice(0, 3));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MainContainer>
      <TopBar setIsMenuOpen={setIsMenuOpen} />
      <Content>
        <TitleArea>
          <Title>
            <TitleSentence>
              <div className="bold">GLOT Writing.&nbsp;</div>선택하고 변형하면서{'\n'}
            </TitleSentence>
            <TitleSentence>완성하는 설명문과 논설문의 작문</TitleSentence>
          </Title>
          <Button
            onClick={() => {
              window.open(window.origin + '/writing', '_blank');
            }}
          >
            무료 체험하기
            <img
              src={vector}
              alt="vector"
              style={{
                width: '18px',
                height: '15px',
                marginLeft: '8px'
              }}
            />
          </Button>
        </TitleArea>
        <ContentArea>
          <Sentence>
            <div className="highlight">하나, </div> 원하는 문장을 입력하면, GLOT Writing이 전개 유형들을 추천해 주어요.
            {'\n'}
          </Sentence>
          <Sentence>
            <div className="highlight">둘, </div> 전개 유형들 중에서 하나를 선택하면, GLOT Writing이 사용자가 선택한
            전개 유형에 해당하는 문장들을 추천해 주어요.{'\n'}
          </Sentence>
          <Sentence>
            <div className="highlight">셋, </div> 전개 유형에 해당하는 문장들 중에서 하나를 선택하거나 전개 유형에
            해당하는 문장을 직접 입력하면서, 원하는 작문을 완성하세요.
          </Sentence>
        </ContentArea>
        {isTablet ? (
          <ImageArea>
            <img
              src={mobile}
              alt="mobile"
              style={{
                width: '100%',
                height: 'auto'
              }}
            />
          </ImageArea>
        ) : (
          <ImageArea>
            <img
              src={banner}
              alt="banner"
              style={{
                width: '100%',
                height: 'auto',
                // objectFit: 'cover',
                borderRadius: '30px'
              }}
            />
          </ImageArea>
        )}
        <TextArea>
          GLOT Writing 1.0의 고객에 대한 배려{'\n'}
          <Bold>사용자를 배려한 여러 가지 GLOT Writing</Bold>
        </TextArea>
        <BannerArea>
          {plan.map((item, index) => (
            <Banner key={index}>
              <BannerTitle
                style={{
                  backgroundColor: item.id === 1 ? '#E5D544' : item.id === 2 ? '#3290FF' : '#FF6B6B',
                  color: 'rgba(255, 255, 255, 1)'
                }}
              >
                {item.name}
              </BannerTitle>
              <BannerPrice>
                {item.id === 1 ? '무료' : new Intl.NumberFormat().format(item.monthlyPrice)}
                {item.id === 1 ? (
                  ''
                ) : (
                  <Small style={{ fontSize: '28px', lineHeight: '32px', marginBottom: '3px' }}>원</Small>
                )}
                {item.id === 1 ? '' : <Gray>/월</Gray>}
                {item.detail.perUser ? <Gray>(1인기준)</Gray> : <></>}
              </BannerPrice>
              <BannerContent>
                {item.detail.contentList.map((line, index) => (
                  <>
                    <BannerContentLine key={index}>
                      <BannerContentTitle>{line.subtitle}</BannerContentTitle>
                    </BannerContentLine>
                    {line.subContentList.map((content, index) => (
                      <BannerContentLine key={index} style={{ marginBottom: '12px' }}>
                        <BannerContentIcon>✔️</BannerContentIcon>
                        <div>
                          <BannerContentText>{content.content}</BannerContentText>
                          <BannerContentText>
                            {content.description ? '(' + content.description + ')' : ''}
                          </BannerContentText>
                        </div>
                      </BannerContentLine>
                    ))}
                  </>
                ))}
              </BannerContent>
              <ButtonContent>
                <CheckButton
                  style={{
                    backgroundColor: item.id === 1 ? '#E5D544' : item.id === 2 ? '#3290FF' : '#FF6B6B',
                    display: item.id === 1 ? 'none' : 'flex'
                  }}
                  onClick={() => {
                    window.open(window.origin + '/payment', '_blank');
                  }}
                >
                  로그인 후 구매하기
                </CheckButton>
              </ButtonContent>
            </Banner>
          ))}
        </BannerArea>
      </Content>
      <MainFooter />
      {isMenuOpen && <Menu setIsMenuOpen={setIsMenuOpen} />}
    </MainContainer>
  );
}

const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(242, 243, 245, 1);
`;

const TitleArea = styled.div`
  width: 1064px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 74px;
  @media (max-width: 1064px) {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Title = styled.div`
  width: 100%;
`;

const TitleSentence = styled.div`
  width: 100%;
  font-size: 48px;
  font-weight: 400;
  line-height: 67.2px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(17, 17, 17, 1);
  white-space: pre-line;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  div.bold {
    font-weight: 700;
  }
  @media (max-width: 1064px) {
    justify-content: center;
  }
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 44.8px;
  }
  @media (max-width: 481px) {
    font-size: 24px;
    line-height: 33.6px;
  }
`;

const Button = styled.div`
  width: 200px;
  height: 22px;
  cursor: pointer;
  color: rgba(17, 17, 17, 1);
  font-size: 22px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.03em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
  @media (max-width: 1064px) {
    margin-top: 32px;
  }
`;

const ContentArea = styled.div`
  width: 1064px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  white-space: pre-line;
  margin: 0 auto;
  margin-top: 52px;
  div.highlight {
    color: rgba(50, 144, 255, 1); // 변경하고자 하는 색상으로 설정
    display: inline; // 줄바꿈 방지
  }
  @media (max-width: 1064px) {
    width: 90%;
  }
`;

const Sentence = styled.div`
  width: 100%;
  font-size: 23px;
  font-weight: 500;
  line-height: 52.8px;
  letter-spacing: -0.03em;
  color: rgba(60, 61, 63, 1);
  white-space: pre-line;
  word-break: keep-all;
  @media (max-width: 1064px) {
    font-size: 20px;
    line-height: 38px;
  }
  @media (max-width: 768px) {
    font-size: 17px;
    line-height: 30px;
  }
  @media (max-width: 481px) {
    font-size: 12px;
    line-height: 24px;
  }
`;

const ImageArea = styled.div`
  width: 1064px;
  height: 574px;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 1);
  margin: 0 auto;
  margin-top: 28px;
  @media (max-width: 1064px) {
    width: 90%;
    height: auto;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    border-radius: 0px;
  }
`;

const TextArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 40px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: -0.03em;
  color: rgba(17, 17, 17, 1);
  white-space: pre-line;
  margin-top: 82px;
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 44.8px;
  }
  @media (max-width: 481px) {
    font-size: 22px;
    line-height: 33.6px;
  }
`;

const BannerArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 53px;
  margin-bottom: 96px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Banner = styled.div`
  width: 385px;
  height: 560px;
  border-radius: 24px;
  background-color: rgba(255, 255, 255, 1);
  margin: 0 18px;
  box-shadow: 0px 5px 60px 0px rgba(62, 96, 130, 0.16);
  @media (max-width: 768px) {
    margin-top: 48px;
    width: 90%;
  }
`;

const BannerTitle = styled.div`
  width: 100%;
  height: 64px;
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  letter-spacing: -0.03em;
  text-align: center;
  color: rgba(17, 17, 17, 1);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BannerPrice = styled.div`
  width: calc(100% - 48px);
  height: 52px;
  padding-top: 16px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: -0.03em;
  text-align: center;
  color: rgba(17, 17, 17, 1);
  display: flex;
  justify-content: flex-start;
  align-items: end;
  border-bottom: 1px solid rgba(242, 243, 245, 1);
  @media (max-width: 768px) {
    font-weight: 500;
  }
`;

const BannerContent = styled.div`
  width: calc(100% - 48px);
  height: 244px;
  padding: 24px;
  padding-top: 12px;
  font-size: 17px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(17, 17, 17, 1);
  white-space: pre-line;
`;

const BannerContentLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 12px;
`;

const BannerContentIcon = styled.div`
  height: 100%;
  font-size: 17px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(17, 17, 17, 1);
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const BannerContentTitle = styled.div`
  width: calc(100% - 26px);
  font-size: 17px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(17, 17, 17, 1);
  justify-content: flex-start;
  align-items: center;
  word-break: keep-all;
`;

const BannerContentText = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(17, 17, 17, 1);
  justify-content: flex-start;
  align-items: center;
  margin-left: 8px;
  word-break: keep-all;
`;

const Small = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(17, 17, 17, 1);
`;

const Bold = styled.span`
  font-weight: 700;
  letter-spacing: -0.03em;
  text-align: left;
  color: rgba(17, 17, 17, 1);
  @media (max-width: 768px) {
    font-weight: 600;
  }
`;

const Gray = styled.span`
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.03em;
  color: rgba(195, 202, 211, 1);
  margin-left: 3px;
  margin-bottom: 6px;
`;

const ButtonContent = styled.div`
  width: calc(100% - 48px);
  height: 56px;
  padding: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckButton = styled.div`
  width: 100%;
  height: 56px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.03em;
  display: flex;
  justify-content: center;
  align-items: center;
`;
