import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useCookies } from 'react-cookie'
import { useRecoilState } from 'recoil'
import { LoginState, UserType } from '../../data/Atom'

import visible from '../../asset/visible.png'
import invisible from '../../asset/hidden.png'

import { UsePostApi, UsePatchApi } from '../../services/http'
import { customNotify } from '../../util/customNotify'
import { AUTH_MESSAGE } from '../../constants/CustomMessage'

export default function ChagePw(props) {
  const navigate = useNavigate()
  const [cookies, setCookie, removeCookie] = useCookies()

  const [loginState, setLoginState] = useRecoilState(LoginState)
  const [userType, setUserType] = useRecoilState(UserType)

  const [visiblePw, setVisiblePw] = useState(false)
  const [visibleNewPw, setVisibleNewPw] = useState(false)
  const [visibleNewPwCheck, setVisibleNewPwCheck] = useState(false)

  const [pw, setPw] = useState('')
  const [newPw, setNewPw] = useState('')
  const [newPwCheck, setNewPwCheck] = useState('')

  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/ //영문, 숫자 조합 8자리 이상
  const isValidPw = passwordRegex.test(newPw)

  const handleLogout = async () => {
    try {
      await UsePostApi('/auth/logout', { auth: localStorage.getItem('token'), refresh: cookies.refresh })
    } catch (error) {
      console.error('로그아웃 중 오류 발생:', error)
    }
    removeCookie('token')
    localStorage.removeItem('token')
    setLoginState(false)
    setUserType('FREE')
  }

  const onClickChangePw = async () => {
    try {
      if (isValidPw) {
        const response = await UsePostApi('/auth/login', { accountId: props.id, password: pw })
        if (response.status === 200) {
          if (newPw === newPwCheck) {
            const response = await UsePatchApi(
              '/user/info',
              { name: null, mobile: null, email: null, password: newPw, language: null },
              { auth: localStorage.getItem('token') }
            )
            customNotify(AUTH_MESSAGE.PASSWORD_CHANGE_SUCCESS, 'success')
            handleLogout()
            navigate('/')
          } else {
            customNotify(AUTH_MESSAGE.NEW_PASSWORD_NOT_MATCH, 'error')
          }
        }
      } else {
        customNotify(AUTH_MESSAGE.NEW_PASSWORD_NOT_VALID, 'error')
      }
    } catch (error) {
      customNotify(AUTH_MESSAGE.PASSWORD_NOT_VALID, 'error')
    }
  }

  return (
    <ModalContainer>
      <Text>
        <Title>비밀번호 변경</Title>
        <Descriptions>
          새로운 비밀번호는 현재 비밀번호와 다르게 입력해야 합니다.{'\n'}
          비밀번호는 영문, 숫자(특수문자) 조합 8자리 이상 입력하시기 바랍니다.{'\n'}
          비밀번호 변경 후 새로운 비밀번호로 로그인하시기 발합니다.
        </Descriptions>
      </Text>
      <Content>
        <Label>현재 비밀번호</Label>
        <Input
          type={visiblePw ? 'text' : 'password'}
          placeholder="기존 비밀번호를 입력해주세요"
          value={pw}
          onChange={e => setPw(e.target.value)}
        />
        <Icon
          style={{
            top: '215px'
          }}
        >
          <img
            src={visiblePw ? visible : invisible}
            alt="visible"
            style={{
              width: '20px',
              height: '20px'
            }}
            onClick={() => setVisiblePw(!visiblePw)}
          />
        </Icon>
        <Label>새로운 비밀번호</Label>
        <Input
          type={visibleNewPw ? 'text' : 'password'}
          placeholder="새로운 비밀번호를 입력해주세요"
          value={newPw}
          onChange={e => setNewPw(e.target.value)}
        />
        <Icon
          style={{
            top: '310px'
          }}
        >
          <img
            src={visibleNewPw ? visible : invisible}
            alt="visible"
            style={{
              width: '20px',
              height: '20px'
            }}
            onClick={() => setVisibleNewPw(!visibleNewPw)}
          />
        </Icon>
        <Label>새로운 비밀번호 확인</Label>
        <Input
          type={visibleNewPwCheck ? 'text' : 'password'}
          placeholder="새로운 비밀번호를 다시 입력해주세요"
          value={newPwCheck}
          onChange={e => setNewPwCheck(e.target.value)}
        />
        <Icon
          style={{
            top: '405px'
          }}
        >
          <img
            src={visibleNewPwCheck ? visible : invisible}
            alt="visible"
            style={{
              width: '20px',
              height: '20px'
            }}
            onClick={() => setVisibleNewPwCheck(!visibleNewPwCheck)}
          />
        </Icon>
      </Content>
      <ButtonArea>
        <Button onClick={onClickChangePw}>변경</Button>
        <Button
          onClick={() => props.setModal(false)}
          style={{
            backgroundColor: '#EAEBED',
            color: '#97989A'
          }}
        >
          취소
        </Button>
      </ButtonArea>
    </ModalContainer>
  )
}

const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 500px;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 12px;
  z-index: 999;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Text = styled.div`
  width: 100%;
  margin-bottom: 24px;
`

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
`

const Descriptions = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  letter-spacing: -0.03em;
  color: #97989a;
  white-space: pre-line;
`

const Content = styled.div`
  width: 100%;
`

const Label = styled.div`
  font-size: 14px;
  color: #6f7071;
  margin-bottom: 8px;
`

const Input = styled.input`
    width: calc(100% - 24px);
    height: 40px;
    border: 1px solid #EAEBED;
    border-radius: 10px;
    padding: 0 12px;
    margin-bottom: 28px;
    &::placeholder {
        font-size: 14px;
        color: #B7B8BA;
`

const Icon = styled.div`
  position: absolute;
  right: 28px;
  transform: translateY(-50%);
  cursor: pointer;
`

const ButtonArea = styled.div`
  width: 100%;
  height: 45px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
`

const Button = styled.div`
  width: 200px;
  height: 50px;
  border: none;
  background-color: #3290ff;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 8px;
`
