import React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { UseGetApi, UsePatchApi } from '../../services/http';

import Sidebar from './Components/Sidebar';

export default function PlanBoard() {
  const [contents, setContents] = useState([{ subtitle: '', subContentList: [{ content: '', description: '' }] }]);
  const [planList, setPlanList] = useState([]);
  const [planName, setPlanName] = useState(''); // 플랜명
  const [planId, setPlanId] = useState(''); // 플랜명
  const [originalPrice, setOriginalPrice] = useState('');
  const [monthlyPrice, setMonthlyPrice] = useState('');
  const [yearlyPrice, setYearlyPrice] = useState(''); // 연간 가격
  const [tokenMax, setTokenMax] = useState(''); // 토큰 사용량
  const [perUser, setPerUser] = useState(false); // 1 user
  const [numberMax, setNumberMax] = useState(0); // 사용 횟수
  const fetchData = async () => {
    const response = await UseGetApi('/plan', { auth: localStorage.getItem('admin_Token') });
    setPlanList(response.data);
  };

  const detailData = async () => {
    const response = await UseGetApi(`/plan/${planId}`, { auth: localStorage.getItem('admin_Token') });
    setPlanName(response.data.name);
    setOriginalPrice(response.data.originalPrice);
    setMonthlyPrice(response.data.monthlyPrice);
    setYearlyPrice(response.data.yearlyPrice);
    setContents(response.data.detail.contentList);
    setPerUser(response.data.detail.perUser);
    setNumberMax(response.data.numberMax);
    setTokenMax(response.data.amountMax.toLocaleString('ko-KR', { style: 'currency', currency: 'KRW' }));
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (planId) {
      detailData();
    }
  }, [planId]);

  const onClickSave = async () => {
    try {
      const tempt = { perUser: perUser, contentList: contents };
      await UsePatchApi(
        `/admin/plan/${planId}`,
        {
          name: planName,
          originalPrice: parseInt(originalPrice),
          monthlyPrice: parseInt(monthlyPrice),
          yearlyPrice: parseInt(yearlyPrice),
          numberMax: parseInt(numberMax),
          amountMax: parseFloat(tokenMax.replace(/[^\d.]/g, '')),
          detail: tempt
        },
        { auth: localStorage.getItem('admin_Token') }
      );
      alert('수정되었습니다.');
      fetchData();
    } catch (error) {
      alert(error.message);
    }
  };

  const onClickAddContent = () => {
    const updatedContents = [...contents];
    updatedContents.push({ subtitle: '', subContentList: [{ content: '', description: '' }] });
    setContents(updatedContents);
  };

  const onClickDeleteContent = () => {
    const updatedContents = [...contents];
    updatedContents.splice(-1, 1);
    setContents(updatedContents);
  };

  const onClickAddDetailContent = index => {
    const updatedContents = [...contents];
    updatedContents[index].subContentList.push({ content: '', description: '' });
    setContents(updatedContents);
  };

  const onClickDeleteDetailContent = index => {
    const updatedContents = [...contents];
    updatedContents[index].subContentList.splice(-1, 1);
    setContents(updatedContents);
  };

  const formatCurrency = event => {
    // Get the input value as a number
    const value = parseFloat(event.target.value);

    // Check if the input is a valid number
    if (!isNaN(value)) {
      // Format the number as Korean won
      const formattedValue = value.toLocaleString('ko-KR', { style: 'currency', currency: 'KRW' });

      // Update the state with the formatted value
      setTokenMax(formattedValue);
    } else {
      // If the input is not a valid number, update the state with the original value
      setTokenMax(event.target.value);
    }
  };
  return (
    <div>
      <Container>
        <Sidebar />
        <InnerContainer>
          <Title>요금제 관리</Title>
          <Content>
            <LeftArea>
              <TitleArea>
                <ContentTitle>플랜명</ContentTitle>
              </TitleArea>
              {planList.map((plan, index) => (
                <MenuArea key={plan.name}>
                  <Menu
                    key={plan.name}
                    onClick={() => {
                      setPlanId(plan.id);
                    }}
                  >
                    {plan.name}
                  </Menu>
                </MenuArea>
              ))}
            </LeftArea>
            <RightArea>
              <TitleArea>
                <ContentTitle>상세 정보</ContentTitle>
                <Button onClick={onClickSave}>저장</Button>
              </TitleArea>
              <ContentArea>
                <Line>
                  <Text>플랜명</Text>
                  <Input
                    value={planName}
                    onChange={e => {
                      setPlanName(e.target.value);
                    }}
                  ></Input>
                </Line>
                <Line>
                  <Text>기존 가격</Text>
                  <Input
                    value={originalPrice}
                    onChange={e => {
                      setOriginalPrice(e.target.value);
                    }}
                  ></Input>
                </Line>
                <Line>
                  <Text>판매 가격</Text>
                  <Input
                    value={monthlyPrice}
                    onChange={e => {
                      setMonthlyPrice(e.target.value);
                    }}
                  ></Input>
                </Line>
                {planName === 'GLOT 게스트' && (
                  <Line>
                    <Text>사용 횟수</Text>
                    <Input
                      value={numberMax}
                      onChange={e => {
                        setNumberMax(e.target.value);
                      }}
                    ></Input>
                  </Line>
                )}
                <Line>
                  <Text>연간 가격</Text>
                  <Input
                    value={yearlyPrice}
                    onChange={e => {
                      setYearlyPrice(e.target.value);
                    }}
                  ></Input>
                </Line>
                <Line>
                  <Text>토큰 사용량</Text>
                  <Input
                    value={tokenMax.toLocaleString('ko-KR', { style: 'currency', currency: 'KRW' })}
                    onChange={formatCurrency}
                    placeholder={`￦${tokenMax}`}
                  ></Input>
                </Line>
                <Line>
                  <Text>인당 가격 알림</Text>
                  <CheckBox>
                    <input
                      type="checkbox"
                      checked={perUser}
                      onChange={() => {
                        setPerUser(!perUser);
                      }}
                    />
                    <span>yes (기관 대상 요금제일 경우 체크)</span>
                  </CheckBox>
                </Line>
                <Line
                  style={{
                    alignItems: 'flex-start'
                  }}
                >
                  <Text
                    style={{
                      marginTop: '10px',
                      width: '100px'
                    }}
                  >
                    내용
                  </Text>
                  <OutInputArea>
                    <InputArea>
                      {contents &&
                        contents.map((content, index) => (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'flex-start'
                            }}
                          >
                            <WritingContent key={index}>
                              <InputLine>
                                <Text
                                  style={{
                                    width: '60px'
                                  }}
                                >
                                  소제목
                                </Text>
                                <Input
                                  style={{
                                    width: '100%'
                                  }}
                                  value={content.subtitle}
                                  onChange={e => {
                                    const updatedContents = [...contents];
                                    updatedContents[index].subtitle = e.target.value;
                                    setContents(updatedContents);
                                  }}
                                  placeholder="제목을 입력하세요."
                                />
                              </InputLine>
                              {content.subContentList &&
                                content.subContentList.map((subContent, subIndex) => (
                                  <div
                                    style={{
                                      marginLeft: '40px',
                                      marginBottom: '10px',
                                      width: '90%',
                                      border: '1px solid #a4a4a4',
                                      padding: '10px'
                                    }}
                                  >
                                    <InputLine
                                      key={subIndex}
                                      style={{
                                        alignItems: 'flex-start'
                                      }}
                                    >
                                      <Text
                                        style={{
                                          marginTop: '10px',
                                          width: '60px'
                                        }}
                                      >
                                        내용
                                      </Text>
                                      <Input
                                        style={{
                                          width: '100%'
                                        }}
                                        value={subContent.content}
                                        onChange={e => {
                                          const updatedContents = [...contents];
                                          updatedContents[index].subContentList[subIndex].content = e.target.value;
                                          setContents(updatedContents);
                                        }}
                                      />
                                    </InputLine>
                                    <InputLine
                                      key={subIndex}
                                      style={{
                                        alignItems: 'flex-start',
                                        marginBottom: '0px'
                                      }}
                                    >
                                      <Text
                                        style={{
                                          marginTop: '10px',
                                          width: '60px'
                                        }}
                                      >
                                        추가설명
                                      </Text>
                                      <Input
                                        style={{
                                          width: '100%'
                                        }}
                                        value={subContent.description}
                                        onChange={e => {
                                          const updatedContents = [...contents];
                                          updatedContents[index].subContentList[subIndex].description = e.target.value;
                                          setContents(updatedContents);
                                        }}
                                      />
                                    </InputLine>
                                  </div>
                                ))}
                            </WritingContent>
                            <ButtonArea style={{ marginTop: '10px' }}>
                              <Icon
                                onClick={() => {
                                  onClickAddDetailContent(index);
                                }}
                                style={{
                                  border: '1px solid black'
                                }}
                              >
                                +
                              </Icon>
                              <Icon
                                onClick={() => {
                                  onClickDeleteDetailContent(index);
                                }}
                                style={{
                                  border: '1px solid black'
                                }}
                              >
                                -
                              </Icon>
                            </ButtonArea>
                          </div>
                        ))}
                    </InputArea>
                  </OutInputArea>
                  <ButtonArea>
                    <Icon onClick={onClickAddContent}>+</Icon>
                    <Icon onClick={onClickDeleteContent}>-</Icon>
                  </ButtonArea>
                </Line>
              </ContentArea>
            </RightArea>
          </Content>
        </InnerContainer>
      </Container>
    </div>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 70px);
  display: flex;
  flex-direction: row;
  background-color: #fff;
`;

const InnerContainer = styled.div`
  width: calc(100% - 270px);
  height: 100%;
  min-height: 100vh;
  background-color: #fff;
`;

const Title = styled.div`
  width: calc(100% - 42px);
  color: rgb(17, 17, 17, 1);
  padding: 21px;
  font-size: 21px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ButtonArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
`;

const Input = styled.input`
  width: 500px;
  height: 30px;
  border: 1px solid #e5e5e5;
  padding: 0px 10px;
`;

const Button = styled.button`
  width: 60px;
  height: 30px;
  border: none;
  background-color: #3290ff;
  color: white;
  padding: 0px 10px;
  margin-left: 10px;
`;

const Content = styled.div`
  width: calc(100% - 42px);
  margin: auto;
  display: flex;
  flex-direction: row;
`;

const LeftArea = styled.div`
  width: 25%;
  height: 100%;
  min-height: calc(100vh - 70px);
  margin-right: 5px;
  border-right: 1px solid #e5e5e5;
`;

const RightArea = styled.div`
  width: 75%;
  height: 100%;
  min-height: calc(100vh - 70px);
  border-left: 1px solid #e5e5e5;
`;

const TitleArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
`;

const ContentTitle = styled.div`
  width: 100px;
  height: 100%;
  padding: 12px;
  font-size: 13px;
  font-weight: bold;
`;

const ContentArea = styled.div`
  width: 95%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 24px 0px;
`;

const MenuArea = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Menu = styled.div`
  width: calc(100% - 20px);
  height: 30px;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
`;

const Line = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const Text = styled.div`
  width: 100px;
  height: 100%;
  padding-left: 10px;
  font-size: 13px;
  font-weight: bold;
`;

const OutInputArea = styled.div`
  width: 630px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  border: 1px solid #e5e5e5;
  padding: 10px;
`;

const InputArea = styled.div`
  width: calc(100% - 70px);
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #e5e5e5;
  padding: 10px;
`;

const WritingContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
  padding-bottom: 0px;
  margin-bottom: 10px;
`;

const InputLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

const Icon = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #e5e5e5;
  margin-right: 5px;
`;

const CheckBox = styled.label`
  display: flex;
  align-items: center;
`;
