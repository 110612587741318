import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from "styled-components";
import React, { useEffect, useState } from 'react';

export default function MainCarousal(props) {
    const [slideImg1, setSlideImg1] = useState('');
    const [slideImg2, setSlideImg2] = useState('');
    const [slideImg3, setSlideImg3] = useState('');

    useEffect(() => {
        if (props.page === '1') {
            setSlideImg1(require('../../asset/main001_card_1.png'))
            setSlideImg2(require('../../asset/main001_card_2.png'))
            setSlideImg3(require('../../asset/main001_card_3.png'))
        }
        else {
            setSlideImg1(require('../../asset/mobile002_4.png'))  
            setSlideImg2(require('../../asset/mobile002_5.png'))
            setSlideImg3(require('../../asset/mobile002_6.png'))
        }
    }, [props.page])

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        autoplay: false,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
    };

    return (
        <MainSlideArea>
            <Slider {...settings}>
                <CarousalContent>
                    <Image
                        src={slideImg1}
                    />
                </CarousalContent>
                <CarousalContent>
                    <Image
                        src={slideImg2}
                    />
                </CarousalContent>
                <CarousalContent>
                    <Image
                        src={slideImg3}
                    />
                </CarousalContent>
            </Slider>
        </MainSlideArea>
    );
}

const MainSlideArea = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`;

const CarousalContent = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 0 16px;
`;

const Image = styled.img`
    width: calc(100% - 32px);
    height: auto;
`;
