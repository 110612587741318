import React, { useState, useEffect, useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { SentenceList, Sentence, SentenceType, AdminState, Id } from '../../data/Atom'

import reload from '../../asset/reload.png'
import { sliceLastSentence } from '../../util/sliceLastSentence'
import { getSentences } from '../../services/Sentence'
import { useQueryClient } from 'react-query'

const VIP_ID = 'vip231222'
export default function WritingExampleModal({ coordinates, setIsOptionSelected, writingContent, setWritingContent }) {
  const setSentence = useSetRecoilState(Sentence)
  const isAdmin = useRecoilValue(AdminState)
  const userId = useRecoilValue(Id)
  const isVip = useMemo(() => userId === VIP_ID, [userId])
  const sentenceType = useRecoilValue(SentenceType)
  const [sentenceList, setSentenceList] = useRecoilState(SentenceList)
  const [controller, setController] = useState(new AbortController())
  const content = writingContent.slice(0, -1)

  const [isLoading, setIsLoading] = useState(true) // 초기에 로딩 상태를 설정

  const queryClient = useQueryClient()
  const handleSentenceSet = useCallback(
    (index, letter) => {
      setSentenceList(prev => {
        const newArray = [...prev]
        newArray[index] = letter
        return newArray
      })
    },
    [setSentenceList]
  )
  const handleLoading = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true) // API 요청이 시작될 때 로딩 상태 설정
    getSentences(
      sliceLastSentence(content),
      sentenceType,
      handleSentenceSet,
      handleLoading,
      controller,
      isAdmin,
      isVip
    ).then(() => queryClient.invalidateQueries(['tokenRate']))
    return () => {
      !controller.signal.aborted && controller.abort()
    }
  }, [content, sentenceType, handleSentenceSet, controller, isAdmin, isVip])

  const onClickOption = e => {
    // if(sentenceList.length === 0) {
    //     setSentence('');
    //     setIsOptionSelected(false);
    //     return;
    // }
    // const option = e.target.innerText;
    // setSentence(option);
    // setIsOptionSelected(false);
    setSentence(e.target.innerText)
    setIsOptionSelected(false)
  }

  const onClickSelfInput = () => {
    const option = ''
    setWritingContent(writingContent.slice(0, -1))
    setSentence(option)
    setIsOptionSelected(false)
  }

  const onClickReload = () => {
    setIsLoading(true) // API 요청이 시작될 때 로딩 상태 설정
    setSentenceList([])
    controller.abort()
    setController(new AbortController())
  }

  const onClickClose = () => {
    const option = ''
    setWritingContent(writingContent.slice(0, -1))
    setSentence(option)
    setIsOptionSelected(false)
    setSentenceList([])
    controller.abort()
  }

  return (
    <ModalWrapper coordinates={coordinates}>
      <ModalTitle>
        <Title>GLOT 문장 추천</Title>
        <div style={{ display: 'flex' }}>
          <Button onClick={onClickReload}>
            <Icon src={reload}></Icon>다른 문장 추천
          </Button>
          <Button style={{ color: '#33B864' }} onClick={onClickClose}>
            X
          </Button>
        </div>
      </ModalTitle>
      <ModalContent>
        {isLoading ? ( // 로딩 중일 때 로딩 메시지 또는 스피너를 표시
          <LoadingMessage>
            <Dots>Loading</Dots>
          </LoadingMessage>
        ) : (
          sentenceList &&
          sentenceList.length > 0 &&
          sentenceList.map((sentence, index) => (
            <SelectContent key={index} onClick={onClickOption}>
              <SelectDescription>{sentence}</SelectDescription>
            </SelectContent>
          ))
        )}
        <SelfInput onClick={onClickSelfInput}>
          <SelfInputBox>직접 입력하기</SelfInputBox>
        </SelfInput>
      </ModalContent>
    </ModalWrapper>
  )
}

const ModalWrapper = styled.div`
  position: absolute;
  top: ${props => props.coordinates.y}px;
  left: ${props => props.coordinates.x}px;
  width: 423px;
  background-color: #ffffff;
  box-shadow: 0px 2px 14px 0px rgba(70, 71, 57, 0.12);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  @media (max-width: 768px) {
    width: 100%;
    top: 38vh;
    left: 0px;
    border: 1px solid #eaebed;
    border-radius: 0px;
    box-shadow: none;
    overflow-y: scroll;
  }
`

const ModalTitle = styled.div`
  width: calc(100% - 32px);
  height: 8px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(242, 243, 245, 1);
`

const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #33b864;
  display: flex;
  justify-content: center;
`

const Button = styled.button`
  border: none;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #b7b8ba;
  margin-left: 0px;
  cursor: pointer;
`

const Icon = styled.img`
  width: 12.19px;
  height: 12.19px;
  margin-right: 5px;
`

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const SelectContent = styled.div`
  width: calc(100% - 32px);
  border-bottom: 1px solid rgba(242, 243, 245, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 16px;
  &:hover {
    background: rgba(242, 243, 245, 1);
  }
`

const SelectDescription = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: rgba(17, 17, 17, 1);
  margin-top: 5px;
`

const SelfInput = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SelfInputBox = styled.div`
  width: calc(100% - 32px);
  height: calc(100% - 32px);
  padding: 16px;
  border: none;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 13px;
  font-weight: 400;
  color: rgba(17, 17, 17, 1);
  cursor: pointer;
  &:hover {
    background: rgba(242, 243, 245, 1);
  }
`

const LoadingMessage = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: #33b864;
  padding: 20px;
  @media (max-width: 768px) {
    height: 210px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`
