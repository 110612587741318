import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import { useReactToPrint } from 'react-to-print';
import getCaretCoordinates from 'textarea-caret';
import dayjs from 'dayjs';

import { useRecoilState, useRecoilValue } from 'recoil';
import {
  WritingTitle,
  WritingContent,
  Sentence,
  SentenceList,
  SentenceType,
  UserType,
  LoginState,
  AdminState
} from '../../data/Atom';

import Header from '../../components/Header';
import SideBar from '../../components/Writing/SideBar';
import Backdrop from '../../components/Modal/Backdrop';
import BackdropSoft from '../../components/Modal/BackdropSoft';
import OptionModal from '../../components/Modal/WritingOptionModal';
import ExampleModal from '../../components/Modal/WritingExampleModal';
import VersionNotice from '../../components/Modal/VersionNoticeModal';
import WarningModal from '../../components/Modal/WritingSaveNoticeModal';
import WritingDeleteModal from '../../components/Modal/WritingDeleteModal';

import { UseGetApi, UsePostApi } from '../../services/http';

import { getTodayNotUsingWritingGuide } from '../../util/todayNotUsingWritingGuide';

import open from '../../asset/open.png';
import { customNotify } from '../../util/customNotify';
import { WRITING_MESSAGE } from '../../constants/CustomMessage';
import { isSentenceValid } from '../../util/sentenceValidation';
import { useGetTokenRate } from '../../services/tokenRate';
import WritingGuideModal from '../../components/Modal/WritingGuideModal';
const VIP_ID = 'vip231222';

const DEFAULT_WRITING_DATA = {
  title: '',
  content: ''
};

export default function Writing() {
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });

  const isTablet = useMediaQuery({
    query: '(min-width:769px) and (max-width:1439px)'
  });

  const navigate = useNavigate();
  const { id: writingId } = useParams(); // 작문 고유 id

  const [isSideBarOpen, setIsSideBarOpen] = useState(true); // 사이드바 제어
  const [sidebarHeight, setSidebarHeight] = useState(0); // 사이드바 높이
  const [contentInputHeight, setContentInputHeight] = useState(0); // 작문 내용 높이
  const [writingList, setWritingList] = useState([]); // 작문 리스트
  const [writingListUpdate, setWritingListUpdate] = useState(false); // 사이드바 작문 리스트 업데이트
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 }); // '/' 문자의 위치

  const [showSaveNoticeModal, setShowSaveNoticeModal] = useState(false); // 작문 저장 여부(모달창 제어)
  const [isSlashTyped, setIsSlashTyped] = useState(false); // '/' 문자 입력 여부(모달창 제어)
  const [isVersionNotified, setIsVersionNotified] = useState(false); // 버전 업데이트 알림 여부(모달창 제어)
  const [isOptionSelected, setIsOptionSelected] = useState(false); // 작문 옵션 선택 여부(모달창 제어)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // 작문 삭제 여부(모달창 제어)
  const [pdfButton, setPDFButton] = useState(false); // pdf 저장 버튼 제어
  const [isGuideModalOpen, setIsGuideModalOpen] = useState(() => !getTodayNotUsingWritingGuide()); // guide 모달창 제어

  const [selectOption, setSelectOption] = useRecoilState(SentenceType); // 선택한 작문 옵션
  const [selectOptionExample, setSelectOptionExample] = useRecoilState(SentenceList); // 선택한 작문 옵션의 예시 문장 배열
  const [selectSentence, setSelectSentence] = useRecoilState(Sentence); // 선택한 작문 옵션의 예시 문장
  const [writingTitle, setWritingTitle] = useRecoilState(WritingTitle); // 작문 제목
  const [writingContent, setWritingContent] = useRecoilState(WritingContent); // 작문 내용
  const [originalData, setOriginalData] = useState(null);
  const userType = useRecoilValue(UserType);
  const loginState = useRecoilValue(LoginState);
  const isAdmin = useRecoilValue(AdminState);
  const userId = localStorage.getItem('userID');
  const isVip = useMemo(() => userId === VIP_ID, [userId]);
  const { data: tokenRate } = useGetTokenRate(localStorage.getItem('token'));
  const writingAreaRef = useRef(null);

  const openSideBar = () => {
    setIsSideBarOpen(true);
  };

  const WritingListDataFetch = async () => {
    const response = await UseGetApi('/writing', { auth: localStorage.getItem('token') });
    if (response.status >= 200 && response.status < 300) {
      setWritingList(response.data);
    } else {
      alert('로그인이 만료되었습니다. 다시 로그인해주세요.');
      navigate('/login');
    }
  };

  //작문보드 전체 조회(리스트)
  useEffect(() => {
    if (userType === 'FREE') {
      setWritingList([]);
      return;
    }
    WritingListDataFetch();
  }, [writingListUpdate]);

  // 화면 크기가 변경될 때 사이드바 상태 업데이트
  useEffect(() => {
    if (userType !== 'FREE') {
      setShowSaveNoticeModal(true);
    } else {
      setPDFButton(true);
    }

    if (isMobile) {
      setIsSideBarOpen(false);
    }
    return () => {
      setWritingContent('');
      setWritingTitle('');
    };
  }, []);

  //작문보드 단건 조회(제목,내용)
  useEffect(() => {
    if (writingId) {
      UseGetApi(`/writing/${writingId}`, { auth: localStorage.getItem('token') })
        .then(response => {
          setOriginalData(response.data);
          setWritingTitle(response.data.title);
          setWritingContent(response.data.content);
        })
        .catch(error => {
          navigate('/writing');
          setOriginalData(DEFAULT_WRITING_DATA);
          setWritingTitle('');
          setWritingContent('');
          console.log(error);
        });
    } else {
      setOriginalData(DEFAULT_WRITING_DATA);
      setWritingTitle('');
      setWritingContent('');
    }
  }, [writingId]);

  useEffect(() => {
    setSidebarHeight(contentInputHeight + 213);
  }, [contentInputHeight]);

  // ContentInput애서 caret의 위치를 알아내는 함수
  const handleContentChange = e => {
    setWritingContent(e.target.value);
    if (e.target.value === '/') {
      setIsSlashTyped(false);
    } else if (e.target.value.endsWith('/') && !loginState) {
      setIsSlashTyped(false);
      customNotify(WRITING_MESSAGE.NO_AUTH, 'warning');
    } else if (e.target.value.endsWith('/') && !isAdmin && !isVip && tokenRate >= 1) {
      setIsSlashTyped(false);
      if (userType === 'FREE') {
        setIsVersionNotified(true);
      }
      customNotify(WRITING_MESSAGE.REACH_MAX_TOKEN, 'warning');
    } else if (e.target.value.endsWith('/')) {
      if (!isSentenceValid(writingContent)) {
        return;
      }
      setIsSlashTyped(true);
      // Get caret coordinates on content change
      const textarea = document.getElementById('writing').querySelector('textarea');
      const caretPos = getCaretCoordinates(textarea, textarea.selectionEnd);

      // Get the coordinates of the writing area
      const writingAreaCoordinates = writingAreaRef.current.getBoundingClientRect();

      let newX = caretPos.left + writingAreaCoordinates.left + 40;
      const rightmostX = window.innerWidth - 308;

      if (newX > rightmostX) {
        newX = rightmostX;
      }

      setCoordinates({
        x: newX,
        y: caretPos.top + writingAreaCoordinates.top + 130
      });
    } else {
      setIsSlashTyped(false);
    }
  };

  // ContentInput의 높이를 자동으로 조절하는 함수
  const handleTextAreaResize = e => {
    const textarea = e.target;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
    setContentInputHeight(textarea.scrollHeight);
  };

  // 작문 옵션 선택 시 작문 내용에 추가
  useEffect(() => {
    if (selectOption === '') {
      return;
    }
    // 작문 내용에서 마지막의 '/' 문자를 제거
    const updatedContent = writingContent.endsWith('/') ? writingContent.slice(0, -1) : writingContent;
    // 선택한 옵션 문장을 추가
    const newContent = updatedContent + ' ' + selectSentence;
    setWritingContent(newContent);
    setSelectOption('');
    setSelectOptionExample('');
    setSelectSentence('');
  }, [selectSentence]);

  useEffect(() => {
    if (userType === 'FREE' && !isAdmin && !isVip) {
      if (tokenRate >= 1) {
        setIsVersionNotified(true);
      }
    }
  }, [tokenRate]);
  // 작문 내용이 500자 이상일 때 버전 업데이트 알림
  useEffect(() => {
    // if (userType === 'FREE') {
    //   const newContent = writingContent
    //   const byteCount = new TextEncoder().encode(newContent).length
    //   if (byteCount > 500) {
    //     setIsVersionNotified(true)
    //   }
    // }
    if (!originalData) {
      return;
    }
    if (writingContent.trim() === originalData?.content && writingTitle.trim() === originalData?.title) {
      return;
    }

    const preventClose = e => {
      if (!loginState || !localStorage.getItem('token')) {
        return;
      }

      e.preventDefault();
      e.returnValue = '';
    };

    window.addEventListener('beforeunload', preventClose);

    return () => {
      window.removeEventListener('beforeunload', preventClose);
    };
  }, [writingTitle, writingContent, originalData?.content, originalData?.title, originalData]);

  //jpg로 저장
  const onClickSaveAsJpg = () => {
    domtoimage.toBlob(document.querySelector('#writing')).then(blob => {
      saveAs(blob, `${writingTitle}.jpg`);
    });
  };

  //txt로 저장
  const onClickSaveAsTxt = () => {
    const blob = new Blob([writingContent], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, `${writingTitle}.txt`);
  };

  //인쇄
  const onClickPrint = useReactToPrint({
    content: () => writingAreaRef.current,
    documentTitle: `${writingTitle}`,
    pageStyle: `
            @page {
                size: A4;
                margin: 20;
            }
            @media print {
                body {
                    margin: 0;
                }
            }
        `
  });

  // 작문 저장
  const onClickSave = () => {
    if (writingTitle || writingContent) {
      if (writingTitle.length > 100) {
        alert('제목이 너무 길어서 저장에 실패했습니다.');
        return;
      }

      if (!writingId) {
        //작문을 처음 저장하는 경우 writingId가 없으므로 빈 문자열을 전달
        if (writingTitle === '') {
          const temptTitle = dayjs().format('YYYYMMDD HHmmss');
          UsePostApi(
            '/writing',
            { writingBoardId: writingId, title: temptTitle, content: writingContent },
            { auth: localStorage.getItem('token') }
          )
            .then(response => {
              navigate(`/writing/${response.data.data}`); // 저장하면 작문 고유 id를 받아옴
              setWritingListUpdate(!writingListUpdate); // 사이드바 작문 리스트 업데이트
              alert('작문이 저장되었습니다.');
            })
            .catch(error => {
              console.log(error);
            });
        } else {
          UsePostApi(
            '/writing',
            { writingBoardId: writingId, title: writingTitle, content: writingContent },
            { auth: localStorage.getItem('token') }
          )
            .then(response => {
              navigate(`/writing/${response.data.data}`); // 저장하면 작문 고유 id를 받아옴
              setWritingListUpdate(!writingListUpdate); // 사이드바 작문 리스트 업데이트
              alert('작문이 저장되었습니다.');
            })
            .catch(error => {
              console.log(error);
            });
        }
      } else {
        UsePostApi(
          '/writing',
          { writingBoardId: writingId, title: writingTitle, content: writingContent },
          { auth: localStorage.getItem('token') }
        )
          .then(response => {
            setWritingListUpdate(!writingListUpdate); // 사이드바 작문 리스트 업데이트
            alert('수정이 완료되었습니다.');
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  };

  // originalData or Default값이 세팅되기 전까지는 화면 비워두기
  if (originalData === null) {
    return null;
  }
  return (
    <Container>
      <Header setIsSideBarOpen={setIsSideBarOpen} />
      {isSideBarOpen ? (
        <Content>
          <SideBar
            isSideBarOpen={isSideBarOpen}
            setIsDeleteModalOpen={setIsDeleteModalOpen}
            setIsSideBarOpen={setIsSideBarOpen}
            sidebarHeight={sidebarHeight}
            writingList={writingList}
            setWritingList={setWritingList}
            writingListUpdate={writingListUpdate}
            setWritingListUpdate={setWritingListUpdate}
            onClickPrint={onClickPrint}
            onClickSaveAsJpg={onClickSaveAsJpg}
            onClickSaveAsTxt={onClickSaveAsTxt}
          />
          <InnerContainer isSideBarOpen={isSideBarOpen} isTablet={isTablet}>
            <WritingArea isSideBarOpen={isSideBarOpen} ref={writingAreaRef} loginState={loginState} id="writing">
              <TitleArea>
                <TitleInput
                  type="text"
                  placeholder="제목을 입력하세요."
                  value={writingTitle}
                  onChange={e => setWritingTitle(e.target.value)}
                />
              </TitleArea>
              <ContentArea>
                <ContentInput
                  placeholder={`🖍️ [여기에서부터 작문을 시작하세요.]
                                    1. 한국어나 영어로 원하는 문장을 입력하세요.
                                    2. 문장 뒤에 키보드에서 '/'를 누르세요. [예시] 새해는 해가 바뀌는 것이다./
                                    3. GLOT Writing이 전개 유형들을 추천하면, 하나의 전개 유형을 선택해 주세요.
                                    4. GLOT Writing이 사용자가 선택한 전개 유형에 해당하는 문장들을 추천하면, 하나의 문장을 선택해 주시거나 전개 유형에 해당하는 문장을 직접 입력해 주세요.
                                    5. 2번부터 4번까지의 과정을 반복하면서, 문장을 선택하고 변형하여, 원하는 작문을 완성하세요.


                                   
                                    [반드시 참고해 주세요.]
                                    1. 로그인 하신 후에 사용해 주세요.
                                    2. 추천된 문장 외에 다른 문장들을 추천해 주기를 원하면, '다른 문장 추천 기능'을 눌러 주세요.
                                    3. 추천된 문장 대신에 본인이 원하는 문장을 작성하려면, '직접 입력하기'를 눌러 주세요.
                                    4. 설명문과 논설문의 작문에 익숙하지 않으면 ① 첫 문장 입력 → ② ‘/’ key → ③ 발전형 → ④ 반대형 → ⑤ 결론형으로 글을 전개하는 연습을 하는 것을 추천합니다.
                                    5. 만일 설명문과 논설문의 작문에 익숙하다면, 발전형 · 반대형 · 결론형을 자유자재로 결합하거나, 직접 문장을 입력해서 본인이 원하는 글을 작성할 수 있습니다.
                                    `}
                  value={writingContent}
                  onChange={handleContentChange}
                  onInput={handleTextAreaResize}
                  disabled={isVersionNotified || isSlashTyped || isOptionSelected}
                />
              </ContentArea>
            </WritingArea>
            <ButtonArea loginState={loginState}>
              {pdfButton ? (
                <SaveButton onClick={onClickPrint}>PDF 인쇄</SaveButton>
              ) : (
                <SaveButton onClick={onClickSave}>저장</SaveButton>
              )}
            </ButtonArea>
          </InnerContainer>
        </Content>
      ) : (
        <Content>
          <InnerContainer isSideBarOpen={isSideBarOpen} isTablet={isTablet}>
            <Button onClick={openSideBar}>
              <img
                src={open}
                alt="open"
                style={{
                  width: '14px',
                  height: '14px'
                }}
              />
            </Button>
            <WritingArea isSideBarOpen={isSideBarOpen} loginState={loginState} id="writing">
              <TitleArea>
                <TitleInput
                  type="text"
                  placeholder="제목을 입력해주세요"
                  value={writingTitle}
                  onChange={e => setWritingTitle(e.target.value)}
                />
              </TitleArea>
              <ContentArea ref={writingAreaRef}>
                <ContentInput
                  placeholder={`🖍️ [여기에서부터 작문을 시작하세요.]
                                    1. 한국어나 영어로 원하는 문장을 입력하세요.
                                    2. 문장 뒤에 키보드에서 '/'를 누르세요. [예시] 새해는 해가 바뀌는 것이다./
                                    3. GLOT Writing이 전개 유형들을 추천하면, 하나의 전개 유형을 선택해 주세요.
                                    4. GLOT Writing이 사용자가 선택한 전개 유형에 해당하는 문장들을 추천하면, 하나의 문장을 선택해 주시거나 전개 유형에 해당하는 문장을 직접 입력해 주세요.
                                    5. 2번부터 4번까지의 과정을 반복하면서, 문장을 선택하고 변형하여, 원하는 작문을 완성하세요.


                                    [반드시 참고해 주세요.]
                                    1. 로그인 하신 후에 사용해 주세요.
                                    2. 추천된 문장 외에 다른 문장들을 추천해 주기를 원하면, '다른 문장 추천 기능'을 눌러 주세요.
                                    3. 추천된 문장 대신에 본인이 원하는 문장을 작성하려면, '직접 입력하기'를 눌러 주세요.
                                    4. 설명문과 논설문의 작문에 익숙하지 않으면 ① 첫 문장 입력 → ② ‘/’ key → ③ 발전형 → ④ 반대형 → ⑤ 결론형으로 글을 전개하는 연습을 하는 것을 추천합니다.
                                    5. 만일 설명문과 논설문의 작문에 익숙하다면, 발전형 · 반대형 · 결론형을 자유자재로 결합하거나, 직접 문장을 입력해서 본인이 원하는 글을 작성할 수 있습니다.
                                    `}
                  value={writingContent}
                  onChange={handleContentChange}
                  onInput={handleTextAreaResize}
                  disabled={isVersionNotified || isSlashTyped || isOptionSelected}
                />
              </ContentArea>
            </WritingArea>
            <ButtonArea loginState={loginState}>
              {pdfButton ? (
                <SaveButton onClick={onClickPrint}>PDF 인쇄</SaveButton>
              ) : (
                <SaveButton onClick={onClickSave}>저장</SaveButton>
              )}
            </ButtonArea>
          </InnerContainer>
        </Content>
      )}
      {isSlashTyped && (
        <OptionModal
          setIsSlashTyped={setIsSlashTyped}
          setIsOptionSelected={setIsOptionSelected}
          writingContent={writingContent}
          setWritingContent={setWritingContent}
          coordinates={coordinates}
        />
      )}
      {isSlashTyped && <BackdropSoft />}
      {isOptionSelected && (
        <ExampleModal
          setIsSlashTyped={setIsSlashTyped}
          setIsOptionSelected={setIsOptionSelected}
          writingContent={writingContent}
          setWritingContent={setWritingContent}
          coordinates={coordinates}
        />
      )}
      {isOptionSelected && <BackdropSoft />}
      {isDeleteModalOpen && (
        <WritingDeleteModal
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          writingListUpdate={writingListUpdate}
          setWritingListUpdate={setWritingListUpdate}
        />
      )}
      {isDeleteModalOpen && <Backdrop />}
      {isVersionNotified && <VersionNotice setIsVersionNotified={setIsVersionNotified} />}
      {isVersionNotified && <Backdrop />}
      {showSaveNoticeModal && <WarningModal setShowSaveNoticeModal={setShowSaveNoticeModal} />}
      {isGuideModalOpen && <WritingGuideModal onClose={() => setIsGuideModalOpen(false)} />}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: #f2f3f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    display: block;
  }
`;

const Content = styled.div`
  width: 100%;
  height: calc(100% - 80px); /* 80은 헤더의 높이 */
  min-height: calc(100vh - 80px);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f2f3f5;
`;

const Button = styled.button`
  width: 30px;
  height: 30px;
  position: absolute;
  left: 10px;
  top: 74px;
  border-radius: 50%;
  border: none;
  font-size: 20px;
  cursor: pointer;
  margin-top: 20px;
  background-color: #f2f4f5;
  &:hover {
    background-color: #e5e7e9;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const WritingArea = styled.div`
  width: 598px;
  height: 100%;
  min-height: ${props => (props.loginState ? 'calc(100vh - 217px)' : 'calc(100vh - 167px)')};
  flex: 1;
  background-color: #ffffff;
  border: none;
  display: flex;
  flex-direction: column;
  padding: 48px;
  padding-bottom: 0px;
  margin-top: 24px;
  overflow: auto;
  @media (max-width: 768px) {
    width: calc(100% - 24px);
    height: 100%;
    min-height: calc(100vh - 167px);
    margin-top: 0px;
    padding: 12px;
    padding-bottom: 0px;
  }
`;

const TitleArea = styled.div`
  width: 100%;
  height: 50px;
  outline: none;
  margin-bottom: 20px;
`;

const TitleInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 30px;
  font-weight: 600;
  font-family: 'Pretendard';
  &::placeholder {
    color: #b8b8b8;
  }
  @media (max-width: 768px) {
    font-size: 20px;
    width: calc(100% - 20px);
  }
`;

const ContentArea = styled.div`
  width: 100%;
`;

const ContentInput = styled.textarea`
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 297px);
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Pretendard';
  line-height: 24px;
  white-space: pre-line;
  resize: none;
  background-color: #ffffff;
  &::placeholder {
    color: #b8b8b8;
    line-spacing: -0.03em;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.03em;
    font-family: 'Pretendard';
  }
  &::placeholder:nth-child(2) {
    color: red;
  }
  @media (max-width: 768px) {
    min-height: calc(100vh - 247px);
    width: calc(100% - 20px);
  }
`;

const ButtonArea = styled.div`
  width: 694px;
  height: 60px;
  background-color: #ffffff;
  border-top: 1px solid rgba(239, 239, 239, 1);
  display: ${props => (props.loginState ? 'flex' : 'none')};
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SaveButton = styled.button`
  width: 101px;
  height: 40px;
  border: none;
  border-radius: 99999px;
  background-color: rgba(17, 17, 17, 1);
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 100px;
    height: 45px;
    font-size: 14px;
  }
`;
