import React from "react";
import {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Sidebar from "./Components/Sidebar";
import Paging from "./Components/Paging";

import { UseGetApi, UseDeleteApi } from "../../services/http";

export default function FAQBoard() {
    const navigate = useNavigate();

    const [faqList, setFaqList] = useState([]);
    const [idList, setIdList] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalElements, setTotalElements] = useState(0);
    const [typeFilter, setTypeFilter] = useState(""); // 전체, 메인페이지, pricing
    const [searchType, setSearchType] = useState("TITLE"); // 제목, 제목+내용
    const [keyword, setKeyword] = useState(""); // 검색어

    const fetchData = async () => {
        const response = await UseGetApi('/faq', { auth: localStorage.getItem('admin_Token')}, { page: currentPage - 1, size: 20 });
        setFaqList(response.data.faqList);
        setTotalElements(response.data.totalElement);
    }

    const filterData = async () => {
        const response = await UseGetApi('/faq', { auth: localStorage.getItem('admin_Token')}, { page: currentPage - 1, size: 20, type_filter: typeFilter });
        setFaqList(response.data.faqList);
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        filterData();
    }, [typeFilter]);

    const toggleSelectAll = () => {
        setSelectAll(!selectAll);
        if (selectAll) {
            setIdList([]);
        } else {
            const updatedIdList = faqList.map((faq) => faq.id);
            setIdList(updatedIdList);
        }
    }

    const handleCheckboxChange = (id) => {
        if (idList.includes(id)) {
            const updatedIdList = idList.filter((selectedId) => selectedId !== id);
            setIdList(updatedIdList);
        } else {
            const updatedIdList = [...idList, id];
            setIdList(updatedIdList);
        }
    }

    const onClickDelete = async () => {
        try {
            await Promise.all(idList.map(id => UseDeleteApi(`/admin/faq/${id}`, { auth: localStorage.getItem('admin_Token') })));
            alert('삭제되었습니다.');
        } catch (error) {
            console.error(error);
        }
        window.location.reload();
    }

    const onClickSearch = async () => {
        const response = await UseGetApi('/faq', { auth: localStorage.getItem('admin_Token')}, { page: currentPage - 1, size: 20, search_type: searchType, keyword: keyword });
        setFaqList(response.data.faqList);
    }

    const onClickFAQInfo = (id) => {
        navigate(`/admin/faq/enroll/${id}`);
    }

    const setPage = (page) => {
        setCurrentPage(page);
    }

    return (
        <div>
            <Container>
                <Sidebar />
                <InnerContainer>
                        <Title>
                            FAQ 관리
                            <ButtonArea>
                                <SelectBox
                                    onChange={(e) => setSearchType(e.target.value)}
                                >
                                    <option value={'TITLE'}>제목</option>
                                    <option value={'TITLE_AND_CONTENT'}>제목+내용</option>
                                </SelectBox>
                                <Input 
                                    style={{
                                        width: "200px"
                                    }}
                                    onChange={(e) => setKeyword(e.target.value)}
                                />
                                <Button onClick={onClickSearch}>
                                    검색
                                </Button>
                            </ButtonArea>
                        </Title>
                        <Title>
                            <SelectBox
                                onChange={(e) => setTypeFilter(e.target.value)}
                            >
                                <option value={''}>전체</option>
                                <option value={'MAIN'}>메인페이지</option>
                                <option value={'PRICING'}>pricing</option>
                            </SelectBox>
                            <ButtonArea>
                                <Button onClick={() => navigate('/admin/faq/enroll')}>
                                    등록
                                </Button>
                                <Button onClick={onClickDelete}>
                                    삭제
                                </Button>
                            </ButtonArea>
                        </Title>
                        <TableArea>
                            <Table>
                                <thead>
                                    <tr>
                                        <th style={{width: '5%'}}>
                                            <input 
                                                type="checkbox"
                                                checked={selectAll}
                                                onChange={toggleSelectAll}
                                            />
                                        </th>
                                        <th>No</th>
                                        <th style={{width: '10%'}}>분류</th>
                                        <th style={{ textAlign: 'left', width: '50%'}}>질문</th>
                                        <th>작성자</th>
                                        <th>등록일시</th>
                                        <th>최종수정일시</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {faqList.length === 0 ? (
                                                <tr>
                                                    <td colSpan={7} style={{textAlign: 'center'}}>
                                                        FAQ 내역이 없습니다.
                                                    </td>
                                                </tr>    
                                    ) : (
                                    faqList.map((faq, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <input 
                                                        type="checkbox" 
                                                        checked={idList.includes(faq.id)}
                                                        onChange={() => handleCheckboxChange(faq.id)}
                                                    />
                                                </td>
                                                <td>{(currentPage-1) * 10 + index + 1}</td>
                                                <td>{faq.type}</td>
                                                <td style={{textAlign: 'left'}} onClick={() => onClickFAQInfo(faq.id)}>{faq.title}</td>
                                                <td>{faq.author}</td>
                                                <td>{faq.createdAt}</td>
                                                <td>{faq.lastModifiedAt}</td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                        </TableArea>
                        <Paging page={currentPage} count={totalElements} setPage={setPage} />
                </InnerContainer>
            </Container>
        </div>
    );
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 70px);
    display: flex;
    flex-direction: row;
    background-color: #fff;
`;

const InnerContainer = styled.div`
    width: calc(100% - 270px);
    height: 100%;
    min-height: 100vh;
    background-color: #fff;
`;

const Title = styled.div`
    width: calc(100% - 42px);
    color: rgb(17,17,17,1);
    padding: 21px;
    font-size: 21px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const ButtonArea = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Input = styled.input`
    height: 30px;
    border: 1px solid #e5e5e5;
    padding: 0px 10px;
`;

const SelectBox = styled.select`
    height: 30px;
    border: 1px solid #e5e5e5;
    padding: 0px 10px;
    margin-right: 10px;
`;

const Button = styled.button`
    height: 30px;
    border: 1px solid #e5e5e5;
    padding: 0px 10px;
    margin-left: 10px;
    cursor: pointer;
`;

const TableArea = styled.div`
    width: calc(100% - 42px);
    height: calc(100% - 42px);
    padding: 0 21px;
    overflow: auto;
`;

const Table = styled.table`
    width: 100%;
    border-top: 1px solid black;
    border-collapse: collapse;
    font-size: 12px;
    color: rgb(17,17,17,1);
    text-align: center;
    & th {
        padding: 10px 0px;
        border-bottom: 1px solid black;
        text-align: center;
    }
    & td {
        padding: 10px 0px;
        border-bottom: 1px solid #e5e5e5;
    }
`;