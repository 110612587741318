import { customNotify } from './customNotify'

export const handleTokenRateNotification = (jsonStr, isAdmin, isVip) => {
  const match = jsonStr.match(/\d+(\.\d+)?/)
  const tokenRate = match ? parseFloat(match[0]) : null
  if (tokenRate !== null) {
    if (isVip || isAdmin) return
    if (tokenRate >= 0.5 && tokenRate <= 0.65) {
      customNotify(`현재 토큰 사용량이 50% 이상입니다.`, 'info')
    } else if (tokenRate >= 0.75 && tokenRate <= 0.85) {
      customNotify(`현재 토큰 사용량이 75% 이상입니다.`, 'info')
    } else if (tokenRate === 1) {
      customNotify('토큰 사용량이 100%를 도달했습니다.', 'warning')
    }
    return
  }
}
